var e,
  t = "undefined" != typeof window ? window : void 0,
  i = "undefined" != typeof globalThis ? globalThis : t,
  r = Array.prototype,
  s = r.forEach,
  n = r.indexOf,
  o = null == i ? void 0 : i.navigator,
  a = null == i ? void 0 : i.document,
  l = null == i ? void 0 : i.location,
  c = null == i ? void 0 : i.fetch,
  u = null != i && i.XMLHttpRequest && "withCredentials" in new i.XMLHttpRequest() ? i.XMLHttpRequest : void 0,
  d = null == i ? void 0 : i.AbortController,
  h = null == o ? void 0 : o.userAgent,
  p = null != t ? t : {},
  _ = {
    DEBUG: !1,
    LIB_VERSION: "1.219.0"
  },
  v = "$copy_autocapture",
  g = ["$snapshot", "$pageview", "$pageleave", "$set", "survey dismissed", "survey sent", "survey shown", "$identify", "$groupidentify", "$create_alias", "$$client_ingestion_warning", "$web_experiment_applied", "$feature_enrollment_update", "$feature_flag_called"];
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(e || (e = {}));
var f = ["fatal", "error", "warning", "log", "info", "debug"],
  m = {};
function b(e, t, i) {
  if (B(e)) if (s && e.forEach === s) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (var r = 0, n = e.length; r < n; r++) if (r in e && t.call(i, e[r], r) === m) return;
}
function y(e, t, i) {
  if (!J(e)) {
    if (B(e)) return b(e, t, i);
    if (X(e)) {
      for (var r of e.entries()) if (t.call(i, r[1], r[0]) === m) return;
    } else for (var s in e) if (N.call(e, s) && t.call(i, e[s], s) === m) return;
  }
}
var w = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return b(i, function (t) {
      for (var i in t) void 0 !== t[i] && (e[i] = t[i]);
    }), e;
  },
  S = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return b(i, function (t) {
      b(t, function (t) {
        e.push(t);
      });
    }), e;
  };
function k(e) {
  for (var t = Object.keys(e), i = t.length, r = new Array(i); i--;) r[i] = [t[i], e[t[i]]];
  return r;
}
var E = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  x = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        return e.apply(this, i);
      } catch (e) {
        te.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), te.critical(e);
      }
    };
  },
  I = function (e) {
    var t = {};
    return y(e, function (e, i) {
      W(e) && e.length > 0 && (t[i] = e);
    }), t;
  };
function P(e, t) {
  return i = e, r = e => W(e) && !G(t) ? e.slice(0, t) : e, s = new Set(), function e(t, i) {
    return t !== Object(t) ? r ? r(t, i) : t : s.has(t) ? void 0 : (s.add(t), B(t) ? (n = [], b(t, t => {
      n.push(e(t));
    })) : (n = {}, y(t, (t, i) => {
      s.has(t) || (n[i] = e(t, i));
    })), n);
    var n;
  }(i);
  var i, r, s;
}
var C = ["herokuapp.com", "vercel.app", "netlify.app"];
function F(e) {
  var t = null == e ? void 0 : e.hostname;
  if (!W(t)) return !1;
  var i = t.split(".").slice(-2).join(".");
  for (var r of C) if (i === r) return !1;
  return !0;
}
function R(e, t) {
  for (var i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
function T(e, t, i, r) {
  var {
    capture: s = !1,
    passive: n = !0
  } = null != r ? r : {};
  null == e || e.addEventListener(t, i, {
    capture: s,
    passive: n
  });
}
function $(e, t) {
  return -1 !== e.indexOf(t);
}
var O = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  },
  M = function (e) {
    return e.replace(/^\$/, "");
  };
var A = function (e, t) {
    if (!function (e) {
      try {
        new RegExp(e);
      } catch (e) {
        return !1;
      }
      return !0;
    }(t)) return !1;
    try {
      return new RegExp(t).test(e);
    } catch (e) {
      return !1;
    }
  },
  L = Array.isArray,
  D = Object.prototype,
  N = D.hasOwnProperty,
  q = D.toString,
  B = L || function (e) {
    return "[object Array]" === q.call(e);
  },
  H = e => "function" == typeof e,
  U = e => e === Object(e) && !B(e),
  j = e => {
    if (U(e)) {
      for (var t in e) if (N.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  z = e => void 0 === e,
  W = e => "[object String]" == q.call(e),
  V = e => W(e) && 0 === e.trim().length,
  G = e => null === e,
  J = e => z(e) || G(e),
  Y = e => "[object Number]" == q.call(e),
  K = e => "[object Boolean]" === q.call(e),
  X = e => e instanceof FormData,
  Q = e => e instanceof Error,
  Z = e => $(g, e),
  ee = e => {
    var i = {
      _log: function (i) {
        if (t && (_.DEBUG || p.POSTHOG_DEBUG) && !z(t.console) && t.console) {
          for (var r = ("__rrweb_original__" in t.console[i]) ? t.console[i].__rrweb_original__ : t.console[i], s = arguments.length, n = new Array(s > 1 ? s - 1 : 0), o = 1; o < s; o++) n[o - 1] = arguments[o];
          r(e, ...n);
        }
      },
      info: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("log", ...t);
      },
      warn: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("warn", ...t);
      },
      error: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("error", ...t);
      },
      critical: function () {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        console.error(e, ...i);
      },
      uninitializedWarning: e => {
        i.error("You must initialize PostHog before calling ".concat(e));
      },
      createLogger: t => ee("".concat(e, " ").concat(t))
    };
    return i;
  },
  te = ee("[PostHog.js]"),
  ie = te.createLogger,
  re = ie("[ExternalScriptsLoader]"),
  se = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return re.warn("".concat(t, " was requested but loading of external scripts is disabled.")), i("Loading of external scripts is disabled");
    var r = () => {
      if (!a) return i("document not found");
      var r = a.createElement("script");
      if (r.type = "text/javascript", r.crossOrigin = "anonymous", r.src = t, r.onload = e => i(void 0, e), r.onerror = e => i(e), e.config.prepare_external_dependency_script && (r = e.config.prepare_external_dependency_script(r)), !r) return i("prepare_external_dependency_script returned null");
      var s,
        n = a.querySelectorAll("body > script");
      n.length > 0 ? null === (s = n[0].parentNode) || void 0 === s || s.insertBefore(r, n[0]) : a.body.appendChild(r);
    };
    null != a && a.body ? r() : null == a || a.addEventListener("DOMContentLoaded", r);
  };
function ne(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), i.push.apply(i, r);
  }
  return i;
}
function oe(e) {
  for (var t = 1; t < arguments.length; t++) {
    var i = null != arguments[t] ? arguments[t] : {};
    t % 2 ? ne(Object(i), !0).forEach(function (t) {
      ae(e, t, i[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(i)) : ne(Object(i)).forEach(function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(i, t));
    });
  }
  return e;
}
function ae(e, t, i) {
  return t in e ? Object.defineProperty(e, t, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = i, e;
}
function le(e, t) {
  if (null == e) return {};
  var i,
    r,
    s = function (e, t) {
      if (null == e) return {};
      var i,
        r,
        s = {},
        n = Object.keys(e);
      for (r = 0; r < n.length; r++) i = n[r], t.indexOf(i) >= 0 || (s[i] = e[i]);
      return s;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) i = n[r], t.indexOf(i) >= 0 || Object.prototype.propertyIsEnumerable.call(e, i) && (s[i] = e[i]);
  }
  return s;
}
p.__PosthogExtensions__ = p.__PosthogExtensions__ || {}, p.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  var r = "/static/".concat(t, ".js") + "?v=".concat(e.version);
  if ("remote-config" === t && (r = "/array/".concat(e.config.token, "/config.js")), "toolbar" === t) {
    var s = 3e5,
      n = Math.floor(Date.now() / s) * s;
    r = "".concat(r, "&t=").concat(n);
  }
  var o = e.requestRouter.endpointFor("assets", r);
  se(e, o, i);
}, p.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  var r = e.requestRouter.endpointFor("api", t);
  se(e, r, i);
};
var ce = "$people_distinct_id",
  ue = "__alias",
  de = "__timers",
  he = "$autocapture_disabled_server_side",
  pe = "$heatmaps_enabled_server_side",
  _e = "$exception_capture_enabled_server_side",
  ve = "$web_vitals_enabled_server_side",
  ge = "$dead_clicks_enabled_server_side",
  fe = "$web_vitals_allowed_metrics",
  me = "$session_recording_enabled_server_side",
  be = "$console_log_recording_enabled_server_side",
  ye = "$session_recording_network_payload_capture",
  we = "$session_recording_canvas_recording",
  Se = "$replay_sample_rate",
  ke = "$replay_minimum_duration",
  Ee = "$replay_script_config",
  xe = "$sesid",
  Ie = "$session_is_sampled",
  Pe = "$session_recording_url_trigger_activated_session",
  Ce = "$session_recording_event_trigger_activated_session",
  Fe = "$enabled_feature_flags",
  Re = "$early_access_features",
  Te = "$stored_person_properties",
  $e = "$stored_group_properties",
  Oe = "$surveys",
  Me = "$surveys_activated",
  Ae = "$flag_call_reported",
  Le = "$user_state",
  De = "$client_session_props",
  Ne = "$capture_rate_limit",
  qe = "$initial_campaign_params",
  Be = "$initial_referrer_info",
  He = "$initial_person_info",
  Ue = "$epp",
  je = "__POSTHOG_TOOLBAR__",
  ze = "$posthog_cookieless",
  We = [ce, ue, "__cmpns", de, me, pe, xe, Fe, Le, Re, $e, Te, Oe, Ae, De, Ne, qe, Be, Ue],
  Ve = ie("[FeatureFlags]"),
  Ge = "$active_feature_flags",
  Je = "$override_feature_flags",
  Ye = "$feature_flag_payloads",
  Ke = "$override_feature_flag_payloads",
  Xe = e => {
    var t = {};
    for (var [i, r] of k(e || {})) r && (t[i] = r);
    return t;
  };
class Qe {
  constructor(e) {
    ae(this, "_override_warning", !1), ae(this, "_hasLoadedFlags", !1), ae(this, "_requestInFlight", !1), ae(this, "_reloadingDisabled", !1), ae(this, "_additionalReloadRequested", !1), ae(this, "_decideCalled", !1), ae(this, "_flagsLoadedFromRemote", !1), this.instance = e, this.featureFlagEventHandlers = [];
  }
  decide() {
    if (this.instance.config.__preview_remote_config) this._decideCalled = !0;else {
      var e = !this._reloadDebouncer && (this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load);
      this._callDecideEndpoint({
        disableFlags: e
      });
    }
  }
  get hasLoadedFlags() {
    return this._hasLoadedFlags;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagVariants() {
    var e = this.instance.get_property(Fe),
      t = this.instance.get_property(Je);
    if (!t) return e || {};
    for (var i = w({}, e), r = Object.keys(t), s = 0; s < r.length; s++) i[r[s]] = t[r[s]];
    return this._override_warning || (Ve.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    var e = this.instance.get_property(Ye),
      t = this.instance.get_property(Ke);
    if (!t) return e || {};
    for (var i = w({}, e || {}), r = Object.keys(t), s = 0; s < r.length; s++) i[r[s]] = t[r[s]];
    return this._override_warning || (Ve.warn(" Overriding feature flag payloads!", {
      flagPayloads: e,
      overriddenPayloads: t,
      finalPayloads: i
    }), this._override_warning = !0), i;
  }
  reloadFeatureFlags() {
    this._reloadingDisabled || this.instance.config.advanced_disable_feature_flags || this._reloadDebouncer || (this._reloadDebouncer = setTimeout(() => {
      this._callDecideEndpoint();
    }, 5));
  }
  clearDebouncer() {
    clearTimeout(this._reloadDebouncer), this._reloadDebouncer = void 0;
  }
  ensureFlagsLoaded() {
    this._hasLoadedFlags || this._requestInFlight || this._reloadDebouncer || this.reloadFeatureFlags();
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this._reloadingDisabled = e;
  }
  _callDecideEndpoint(t) {
    if (this.clearDebouncer(), !this.instance.config.advanced_disable_decide) if (this._requestInFlight) this._additionalReloadRequested = !0;else {
      var i = {
        token: this.instance.config.token,
        distinct_id: this.instance.get_distinct_id(),
        groups: this.instance.getGroups(),
        $anon_distinct_id: this.$anon_distinct_id,
        person_properties: this.instance.get_property(Te),
        group_properties: this.instance.get_property($e)
      };
      (null != t && t.disableFlags || this.instance.config.advanced_disable_feature_flags) && (i.disable_flags = !0), this._requestInFlight = !0, this.instance._send_request({
        method: "POST",
        url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
        data: i,
        compression: this.instance.config.disable_compression ? void 0 : e.Base64,
        timeout: this.instance.config.feature_flag_request_timeout_ms,
        callback: e => {
          var t,
            r,
            s = !0;
          (200 === e.statusCode && (this.$anon_distinct_id = void 0, s = !1), this._requestInFlight = !1, this._decideCalled) || (this._decideCalled = !0, this.instance._onRemoteConfig(null !== (r = e.json) && void 0 !== r ? r : {}));
          i.disable_flags || (this._flagsLoadedFromRemote = !s, this.receivedFeatureFlags(null !== (t = e.json) && void 0 !== t ? t : {}, s), this._additionalReloadRequested && (this._additionalReloadRequested = !1, this._callDecideEndpoint()));
        }
      });
    }
  }
  getFeatureFlag(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) {
      var i,
        r,
        s,
        n,
        o,
        a = this.getFlagVariants()[e],
        l = "".concat(a),
        c = this.instance.get_property(Ae) || {};
      if (t.send_event || !("send_event" in t)) if (!(e in c) || !c[e].includes(l)) B(c[e]) ? c[e].push(l) : c[e] = [l], null === (i = this.instance.persistence) || void 0 === i || i.register({
        [Ae]: c
      }), this.instance.capture("$feature_flag_called", {
        $feature_flag: e,
        $feature_flag_response: a,
        $feature_flag_payload: this.getFeatureFlagPayload(e) || null,
        $feature_flag_bootstrapped_response: (null === (r = this.instance.config.bootstrap) || void 0 === r || null === (s = r.featureFlags) || void 0 === s ? void 0 : s[e]) || null,
        $feature_flag_bootstrapped_payload: (null === (n = this.instance.config.bootstrap) || void 0 === n || null === (o = n.featureFlagPayloads) || void 0 === o ? void 0 : o[e]) || null,
        $used_bootstrap_value: !this._flagsLoadedFromRemote
      });
      return a;
    }
    Ve.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  getRemoteConfigPayload(t, i) {
    var r = this.instance.config.token;
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
      data: {
        distinct_id: this.instance.get_distinct_id(),
        token: r
      },
      compression: this.instance.config.disable_compression ? void 0 : e.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => {
        var r,
          s = null === (r = e.json) || void 0 === r ? void 0 : r.featureFlagPayloads;
        i((null == s ? void 0 : s[t]) || void 0);
      }
    });
  }
  isFeatureEnabled(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    Ve.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (this.instance.persistence) {
      this._hasLoadedFlags = !0;
      var i = this.getFlagVariants(),
        r = this.getFlagPayloads();
      !function (e, t) {
        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
          r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          s = e.featureFlags,
          n = e.featureFlagPayloads;
        if (s) if (B(s)) {
          var o = {};
          if (s) for (var a = 0; a < s.length; a++) o[s[a]] = !0;
          t && t.register({
            [Ge]: s,
            [Fe]: o
          });
        } else {
          var l = s,
            c = n;
          e.errorsWhileComputingFlags && (l = oe(oe({}, i), l), c = oe(oe({}, r), c)), t && t.register({
            [Ge]: Object.keys(Xe(l)),
            [Fe]: l || {},
            [Ye]: c || {}
          });
        }
      }(e, this.instance.persistence, i, r), this._fireFeatureFlagsCallbacks(t);
    }
  }
  override(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    Ve.warn("override is deprecated. Please use overrideFeatureFlags instead."), this.overrideFeatureFlags({
      flags: e,
      suppressWarning: t
    });
  }
  overrideFeatureFlags(e) {
    if (!this.instance.__loaded || !this.instance.persistence) return Ve.uninitializedWarning("posthog.feature_flags.overrideFeatureFlags");
    if (!1 === e) return this.instance.persistence.unregister(Je), this.instance.persistence.unregister(Ke), void this._fireFeatureFlagsCallbacks();
    if (e && "object" == typeof e && ("flags" in e || "payloads" in e)) {
      var t,
        i = e;
      if (this._override_warning = Boolean(null !== (t = i.suppressWarning) && void 0 !== t && t), "flags" in i) if (!1 === i.flags) this.instance.persistence.unregister(Je);else if (i.flags) if (B(i.flags)) {
        for (var r = {}, s = 0; s < i.flags.length; s++) r[i.flags[s]] = !0;
        this.instance.persistence.register({
          [Je]: r
        });
      } else this.instance.persistence.register({
        [Je]: i.flags
      });
      return "payloads" in i && (!1 === i.payloads ? this.instance.persistence.unregister(Ke) : i.payloads && this.instance.persistence.register({
        [Ke]: i.payloads
      })), void this._fireFeatureFlagsCallbacks();
    }
    this._fireFeatureFlagsCallbacks();
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this._hasLoadedFlags) {
      var {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i,
      r = (this.instance.get_property(Re) || []).find(t => t.flagKey === e),
      s = {
        ["$feature_enrollment/".concat(e)]: t
      },
      n = {
        $feature_flag: e,
        $feature_enrollment: t,
        $set: s
      };
    r && (n.$early_access_feature_name = r.name), this.instance.capture("$feature_enrollment_update", n), this.setPersonPropertiesForFlags(s, !1);
    var o = oe(oe({}, this.getFlagVariants()), {}, {
      [e]: t
    });
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [Ge]: Object.keys(Xe(o)),
      [Fe]: o
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = this.instance.get_property(Re);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        var i;
        if (t.json) {
          var r = t.json.earlyAccessFeatures;
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Re]: r
          }), e(r);
        }
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    var e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    var {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(r => r(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Te) || {};
    this.instance.register({
      [Te]: oe(oe({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister(Te);
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property($e) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = oe(oe({}, i[t]), e[t]), delete e[t];
    }), this.instance.register({
      [$e]: oe(oe({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      var t = this.instance.get_property($e) || {};
      this.instance.register({
        [$e]: oe(oe({}, t), {}, {
          [e]: {}
        })
      });
    } else this.instance.unregister($e);
  }
}
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return Y(e) && isFinite(e) && Math.floor(e) === e;
});
var Ze = "0123456789abcdef";
class et {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, r) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(r) || e < 0 || t < 0 || i < 0 || r < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || r > 4294967295) throw new RangeError("invalid field value");
    var s = new Uint8Array(16);
    return s[0] = e / Math.pow(2, 40), s[1] = e / Math.pow(2, 32), s[2] = e / Math.pow(2, 24), s[3] = e / Math.pow(2, 16), s[4] = e / Math.pow(2, 8), s[5] = e, s[6] = 112 | t >>> 8, s[7] = t, s[8] = 128 | i >>> 24, s[9] = i >>> 16, s[10] = i >>> 8, s[11] = i, s[12] = r >>> 24, s[13] = r >>> 16, s[14] = r >>> 8, s[15] = r, new et(s);
  }
  toString() {
    for (var e = "", t = 0; t < this.bytes.length; t++) e = e + Ze.charAt(this.bytes[t] >>> 4) + Ze.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new et(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (var t = 0; t < 16; t++) {
      var i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class tt {
  constructor() {
    ae(this, "timestamp", 0), ae(this, "counter", 0), ae(this, "random", new st());
  }
  generate() {
    var e = this.generateOrAbort();
    if (z(e)) {
      this.timestamp = 0;
      var t = this.generateOrAbort();
      if (z(t)) throw new Error("Could not generate UUID after timestamp reset");
      return t;
    }
    return e;
  }
  generateOrAbort() {
    var e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return et.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
var it,
  rt = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
t && !z(t.crypto) && crypto.getRandomValues && (rt = e => crypto.getRandomValues(e));
class st {
  constructor() {
    ae(this, "buffer", new Uint32Array(8)), ae(this, "cursor", 1 / 0);
  }
  nextUint32() {
    return this.cursor >= this.buffer.length && (rt(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
var nt = () => ot().toString(),
  ot = () => (it || (it = new tt())).generate(),
  at = "Thu, 01 Jan 1970 00:00:00 GMT",
  lt = "";
var ct = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function ut(e, t) {
  if (t) {
    var i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : a;
      if (lt) return lt;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var i = e.split("."), r = Math.min(i.length, 8), s = "dmn_chk_" + nt(), n = new RegExp("(^|;)\\s*" + s + "=1"); !lt && r--;) {
        var o = i.slice(r).join("."),
          l = s + "=1;domain=." + o;
        t.cookie = l, n.test(t.cookie) && (t.cookie = l + ";expires=" + at, lt = o);
      }
      return lt;
    }(e);
    if (!i) {
      var r = (e => {
        var t = e.match(ct);
        return t ? t[0] : "";
      })(e);
      r !== i && te.info("Warning: cookie subdomain discovery mismatch", r, i), i = r;
    }
    return i ? "; domain=." + i : "";
  }
  return "";
}
var dt = {
    is_supported: () => !!a,
    error: function (e) {
      te.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (a) {
        try {
          for (var t = e + "=", i = a.cookie.split(";").filter(e => e.length), r = 0; r < i.length; r++) {
            for (var s = i[r]; " " == s.charAt(0);) s = s.substring(1, s.length);
            if (0 === s.indexOf(t)) return decodeURIComponent(s.substring(t.length, s.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(dt.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, i, r, s) {
      if (a) try {
        var n = "",
          o = "",
          l = ut(a.location.hostname, r);
        if (i) {
          var c = new Date();
          c.setTime(c.getTime() + 24 * i * 60 * 60 * 1e3), n = "; expires=" + c.toUTCString();
        }
        s && (o = "; secure");
        var u = e + "=" + encodeURIComponent(JSON.stringify(t)) + n + "; SameSite=Lax; path=/" + l + o;
        return u.length > 3686.4 && te.warn("cookieStore warning: large cookie, len=" + u.length), a.cookie = u, u;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        dt.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  ht = null,
  pt = {
    is_supported: function () {
      if (!G(ht)) return ht;
      var e = !0;
      if (z(t)) e = !1;else try {
        var i = "__mplssupport__";
        pt.set(i, "xyz"), '"xyz"' !== pt.get(i) && (e = !1), pt.remove(i);
      } catch (t) {
        e = !1;
      }
      return e || te.error("localStorage unsupported; falling back to cookie store"), ht = e, e;
    },
    error: function (e) {
      te.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.localStorage.getItem(e);
      } catch (e) {
        pt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(pt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.localStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        pt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.localStorage.removeItem(e);
      } catch (e) {
        pt.error(e);
      }
    }
  },
  _t = ["distinct_id", xe, Ie, Ue, He],
  vt = oe(oe({}, pt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = dt.parse(e) || {};
        } catch (e) {}
        var i = w(t, JSON.parse(pt.get(e) || "{}"));
        return pt.set(e, i), i;
      } catch (e) {}
      return null;
    },
    set: function (e, t, i, r, s, n) {
      try {
        pt.set(e, t, void 0, void 0, n);
        var o = {};
        _t.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && dt.set(e, o, i, r, s, n);
      } catch (e) {
        pt.error(e);
      }
    },
    remove: function (e, i) {
      try {
        null == t || t.localStorage.removeItem(e), dt.remove(e, i);
      } catch (e) {
        pt.error(e);
      }
    }
  }),
  gt = {},
  ft = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      te.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return gt[e] || null;
    },
    parse: function (e) {
      return gt[e] || null;
    },
    set: function (e, t) {
      gt[e] = t;
    },
    remove: function (e) {
      delete gt[e];
    }
  },
  mt = null,
  bt = {
    is_supported: function () {
      if (!G(mt)) return mt;
      if (mt = !0, z(t)) mt = !1;else try {
        var e = "__support__";
        bt.set(e, "xyz"), '"xyz"' !== bt.get(e) && (mt = !1), bt.remove(e);
      } catch (e) {
        mt = !1;
      }
      return mt;
    },
    error: function (e) {
      te.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.sessionStorage.getItem(e);
      } catch (e) {
        bt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(bt.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.sessionStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        bt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.sessionStorage.removeItem(e);
      } catch (e) {
        bt.error(e);
      }
    }
  },
  yt = ["localhost", "127.0.0.1"],
  wt = e => {
    var t = null == a ? void 0 : a.createElement("a");
    return z(t) ? null : (t.href = e, t);
  },
  St = function (e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      s = [];
    return y(e, function (e, r) {
      z(e) || z(r) || "undefined" === r || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(r), s[s.length] = i + "=" + t);
    }), s.join(r);
  },
  kt = function (e, t) {
    for (var i, r = ((e.split("#")[0] || "").split("?")[1] || "").split("&"), s = 0; s < r.length; s++) {
      var n = r[s].split("=");
      if (n[0] === t) {
        i = n;
        break;
      }
    }
    if (!B(i) || i.length < 2) return "";
    var o = i[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      te.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  Et = function (e, t, i) {
    if (!e || !t || !t.length) return e;
    for (var r = e.split("#"), s = r[0] || "", n = r[1], o = s.split("?"), a = o[1], l = o[0], c = (a || "").split("&"), u = [], d = 0; d < c.length; d++) {
      var h = c[d].split("=");
      B(h) && (t.includes(h[0]) ? u.push(h[0] + "=" + i) : u.push(c[d]));
    }
    var p = l;
    return null != a && (p += "?" + u.join("&")), null != n && (p += "#" + n), p;
  },
  xt = function (e, t) {
    var i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  It = "Mobile",
  Pt = "iOS",
  Ct = "Android",
  Ft = "Tablet",
  Rt = Ct + " " + Ft,
  Tt = "iPad",
  $t = "Apple",
  Ot = $t + " Watch",
  Mt = "Safari",
  At = "BlackBerry",
  Lt = "Samsung",
  Dt = Lt + "Browser",
  Nt = Lt + " Internet",
  qt = "Chrome",
  Bt = qt + " OS",
  Ht = qt + " " + Pt,
  Ut = "Internet Explorer",
  jt = Ut + " " + It,
  zt = "Opera",
  Wt = zt + " Mini",
  Vt = "Edge",
  Gt = "Microsoft " + Vt,
  Jt = "Firefox",
  Yt = Jt + " " + Pt,
  Kt = "Nintendo",
  Xt = "PlayStation",
  Qt = "Xbox",
  Zt = Ct + " " + It,
  ei = It + " " + Mt,
  ti = "Windows",
  ii = ti + " Phone",
  ri = "Nokia",
  si = "Ouya",
  ni = "Generic",
  oi = ni + " " + It.toLowerCase(),
  ai = ni + " " + Ft.toLowerCase(),
  li = "Konqueror",
  ci = "(\\d+(\\.\\d+)?)",
  ui = new RegExp("Version/" + ci),
  di = new RegExp(Qt, "i"),
  hi = new RegExp(Xt + " \\w+", "i"),
  pi = new RegExp(Kt + " \\w+", "i"),
  _i = new RegExp(At + "|PlayBook|BB10", "i"),
  vi = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var gi = (e, t) => t && $(t, $t) || function (e) {
    return $(e, Mt) && !$(e, qt) && !$(e, Ct);
  }(e),
  fi = function (e, t) {
    return t = t || "", $(e, " OPR/") && $(e, "Mini") ? Wt : $(e, " OPR/") ? zt : _i.test(e) ? At : $(e, "IE" + It) || $(e, "WPDesktop") ? jt : $(e, Dt) ? Nt : $(e, Vt) || $(e, "Edg/") ? Gt : $(e, "FBIOS") ? "Facebook " + It : $(e, "UCWEB") || $(e, "UCBrowser") ? "UC Browser" : $(e, "CriOS") ? Ht : $(e, "CrMo") || $(e, qt) ? qt : $(e, Ct) && $(e, Mt) ? Zt : $(e, "FxiOS") ? Yt : $(e.toLowerCase(), li.toLowerCase()) ? li : gi(e, t) ? $(e, It) ? ei : Mt : $(e, Jt) ? Jt : $(e, "MSIE") || $(e, "Trident/") ? Ut : $(e, "Gecko") ? Jt : "";
  },
  mi = {
    [jt]: [new RegExp("rv:" + ci)],
    [Gt]: [new RegExp(Vt + "?\\/" + ci)],
    [qt]: [new RegExp("(" + qt + "|CrMo)\\/" + ci)],
    [Ht]: [new RegExp("CriOS\\/" + ci)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + ci)],
    [Mt]: [ui],
    [ei]: [ui],
    [zt]: [new RegExp("(Opera|OPR)\\/" + ci)],
    [Jt]: [new RegExp(Jt + "\\/" + ci)],
    [Yt]: [new RegExp("FxiOS\\/" + ci)],
    [li]: [new RegExp("Konqueror[:/]?" + ci, "i")],
    [At]: [new RegExp(At + " " + ci), ui],
    [Zt]: [new RegExp("android\\s" + ci, "i")],
    [Nt]: [new RegExp(Dt + "\\/" + ci)],
    [Ut]: [new RegExp("(rv:|MSIE )" + ci)],
    Mozilla: [new RegExp("rv:" + ci)]
  },
  bi = [[new RegExp(Qt + "; " + Qt + " (.*?)[);]", "i"), e => [Qt, e && e[1] || ""]], [new RegExp(Kt, "i"), [Kt, ""]], [new RegExp(Xt, "i"), [Xt, ""]], [_i, [At, ""]], [new RegExp(ti, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [ii, ""];
    if (new RegExp(It).test(t) && !/IEMobile\b/.test(t)) return [ti + " " + It, ""];
    var i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      var r = i[1],
        s = vi[r] || "";
      return /arm/i.test(t) && (s = "RT"), [ti, s];
    }
    return [ti, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [Pt, t.join(".")];
    }
    return [Pt, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    var t = "";
    return e && e.length >= 3 && (t = z(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + Ct + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + Ct + ")", "i"), e => {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [Ct, t.join(".")];
    }
    return [Ct, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [Bt, ""]], [/Linux|debian/i, ["Linux", ""]]],
  yi = function (e) {
    return pi.test(e) ? Kt : hi.test(e) ? Xt : di.test(e) ? Qt : new RegExp(si, "i").test(e) ? si : new RegExp("(" + ii + "|WPDesktop)", "i").test(e) ? ii : /iPad/.test(e) ? Tt : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? Ot : _i.test(e) ? At : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(ri, "i").test(e) ? ri : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(It).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? Ct : Rt : Ct : new RegExp("(pda|" + It + ")", "i").test(e) ? oi : new RegExp(Ft, "i").test(e) && !new RegExp(Ft + " pc", "i").test(e) ? ai : "";
  },
  wi = "https?://(.*)",
  Si = ["gclid", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "igshid", "ttclid", "rdt_cid", "irclid", "_kx"],
  ki = S(["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gad_source", "mc_cid"], Si),
  Ei = "<masked>",
  xi = {
    campaignParams: function () {
      var {
        customTrackedParams: e,
        maskPersonalDataProperties: t,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!a) return {};
      var r = t ? S([], Si, i || []) : [];
      return this._campaignParamsFromUrl(Et(a.URL, r, Ei), e);
    },
    _campaignParamsFromUrl: function (e, t) {
      var i = ki.concat(t || []),
        r = {};
      return y(i, function (t) {
        var i = kt(e, t);
        r[t] = i || null;
      }), r;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(wi + "google.([^/?]*)") ? "google" : 0 === e.search(wi + "bing.com") ? "bing" : 0 === e.search(wi + "yahoo.com") ? "yahoo" : 0 === e.search(wi + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      var t = xi._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        r = {};
      if (!G(t)) {
        r.$search_engine = t;
        var s = a ? kt(a.referrer, i) : "";
        s.length && (r.ph_keyword = s);
      }
      return r;
    },
    searchInfo: function () {
      var e = null == a ? void 0 : a.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: fi,
    browserVersion: function (e, t) {
      var i = fi(e, t),
        r = mi[i];
      if (z(r)) return null;
      for (var s = 0; s < r.length; s++) {
        var n = r[s],
          o = e.match(n);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    browserLanguagePrefix: function () {
      var e = this.browserLanguage();
      return "string" == typeof e ? e.split("-")[0] : void 0;
    },
    os: function (e) {
      for (var t = 0; t < bi.length; t++) {
        var [i, r] = bi[t],
          s = i.exec(e),
          n = s && (H(r) ? r(s, e) : r);
        if (n) return n;
      }
      return ["", ""];
    },
    device: yi,
    deviceType: function (e) {
      var t = yi(e);
      return t === Tt || t === Rt || "Kobo" === t || "Kindle Fire" === t || t === ai ? Ft : t === Kt || t === Qt || t === Xt || t === si ? "Console" : t === Ot ? "Wearable" : t ? It : "Desktop";
    },
    referrer: function () {
      return (null == a ? void 0 : a.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != a && a.referrer && (null === (e = wt(a.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    personInfo: function () {
      var {
          maskPersonalDataProperties: e,
          customPersonalDataProperties: t
        } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        i = e ? S([], Si, t || []) : [],
        r = null == l ? void 0 : l.href.substring(0, 1e3);
      return {
        r: this.referrer().substring(0, 1e3),
        u: r ? Et(r, i, Ei) : void 0
      };
    },
    personPropsFromInfo: function (e) {
      var t,
        {
          r: i,
          u: r
        } = e,
        s = {
          $referrer: i,
          $referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = wt(i)) || void 0 === t ? void 0 : t.host
        };
      if (r) {
        s.$current_url = r;
        var n = wt(r);
        s.$host = null == n ? void 0 : n.host, s.$pathname = null == n ? void 0 : n.pathname;
        var o = this._campaignParamsFromUrl(r);
        w(s, o);
      }
      if (i) {
        var a = this._searchInfoFromReferrer(i);
        w(s, a);
      }
      return s;
    },
    initialPersonPropsFromInfo: function (e) {
      var t = this.personPropsFromInfo(e),
        i = {};
      return y(t, function (e, t) {
        i["$initial_".concat(M(t))] = e;
      }), i;
    },
    timezone: function () {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return;
      }
    },
    timezoneOffset: function () {
      try {
        return new Date().getTimezoneOffset();
      } catch (e) {
        return;
      }
    },
    properties: function () {
      var {
        maskPersonalDataProperties: e,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!h) return {};
      var r = e ? S([], Si, i || []) : [],
        [s, n] = xi.os(h);
      return w(I({
        $os: s,
        $os_version: n,
        $browser: xi.browser(h, navigator.vendor),
        $device: xi.device(h),
        $device_type: xi.deviceType(h),
        $timezone: xi.timezone(),
        $timezone_offset: xi.timezoneOffset()
      }), {
        $current_url: Et(null == l ? void 0 : l.href, r, Ei),
        $host: null == l ? void 0 : l.host,
        $pathname: null == l ? void 0 : l.pathname,
        $raw_user_agent: h.length > 1e3 ? h.substring(0, 997) + "..." : h,
        $browser_version: xi.browserVersion(h, navigator.vendor),
        $browser_language: xi.browserLanguage(),
        $browser_language_prefix: xi.browserLanguagePrefix(),
        $screen_height: null == t ? void 0 : t.screen.height,
        $screen_width: null == t ? void 0 : t.screen.width,
        $viewport_height: null == t ? void 0 : t.innerHeight,
        $viewport_width: null == t ? void 0 : t.innerWidth,
        $lib: "web",
        $lib_version: _.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!h) return {};
      var [e, t] = xi.os(h);
      return w(I({
        $os: e,
        $os_version: t,
        $browser: xi.browser(h, navigator.vendor)
      }), {
        $browser_version: xi.browserVersion(h, navigator.vendor)
      });
    }
  },
  Ii = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class Pi {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      var t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && te.info("Persistence loaded", e.persistence, oe({}, this.props)), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    -1 === Ii.indexOf(e.persistence.toLowerCase()) && (te.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    var t = e.persistence.toLowerCase();
    return "localstorage" === t && pt.is_supported() ? pt : "localstorage+cookie" === t && vt.is_supported() ? vt : "sessionstorage" === t && bt.is_supported() ? bt : "memory" === t ? ft : "cookie" === t ? dt : vt.is_supported() ? vt : dt;
  }
  properties() {
    var e = {};
    return y(this.props, function (t, i) {
      if (i === Fe && U(t)) for (var r = Object.keys(t), s = 0; s < r.length; s++) e["$feature/".concat(r[s])] = t[r[s]];else a = i, l = !1, (G(o = We) ? l : n && o.indexOf === n ? -1 != o.indexOf(a) : (y(o, function (e) {
        if (l || (l = e === a)) return m;
      }), l)) || (e[i] = t);
      var o, a, l;
    }), e;
  }
  load() {
    if (!this.disabled) {
      var e = this.storage.parse(this.name);
      e && (this.props = w({}, e));
    }
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (U(e)) {
      z(t) && (t = "None"), this.expire_days = z(i) ? this.default_expiry : i;
      var r = !1;
      if (y(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, r = !0);
      }), r) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (U(e)) {
      this.expire_days = z(t) ? this.default_expiry : t;
      var i = !1;
      if (y(e, (t, r) => {
        e.hasOwnProperty(r) && this.props[r] !== t && (this.props[r] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    if (!this.campaign_params_saved) {
      var e = xi.campaignParams({
        customTrackedParams: this.config.custom_campaign_params,
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
      j(I(e)) || this.register(e), this.campaign_params_saved = !0;
    }
  }
  update_search_keyword() {
    this.register(xi.searchInfo());
  }
  update_referrer_info() {
    this.register_once(xi.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[qe] || this.props[Be] || this.register_once({
      [He]: xi.personInfo({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      })
    }, void 0);
  }
  get_referrer_info() {
    return I({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    var e = {};
    y([Be, qe], t => {
      var i = this.props[t];
      i && y(i, function (t, i) {
        e["$initial_" + M(i)] = t;
      });
    });
    var t = this.props[He];
    if (t) {
      var i = xi.initialPersonPropsFromInfo(t);
      w(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return y(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      var i = this.buildStorage(e),
        r = this.props;
      this.clear(), this.storage = i, this.props = r, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    var i = this.props[de] || {};
    i[e] = t, this.props[de] = i, this.save();
  }
  remove_event_timer(e) {
    var t = (this.props[de] || {})[e];
    return z(t) || (delete this.props[de][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
function Ci(e) {
  var t, i;
  return (null === (t = JSON.stringify(e, (i = [], function (e, t) {
    if (U(t)) {
      for (; i.length > 0 && i[i.length - 1] !== this;) i.pop();
      return i.includes(t) ? "[Circular]" : (i.push(t), t);
    }
    return t;
  }))) || void 0 === t ? void 0 : t.length) || 0;
}
function Fi(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    var i = Math.floor(e.data.length / 2),
      r = e.data.slice(0, i),
      s = e.data.slice(i);
    return [Fi({
      size: Ci(r),
      data: r,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), Fi({
      size: Ci(s),
      data: s,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var Ri = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(Ri || {}),
  Ti = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(Ti || {});
function $i(e) {
  var t;
  return e instanceof Element && (e.id === je || !(null === (t = e.closest) || void 0 === t || !t.call(e, ".toolbar-global-fade-container")));
}
function Oi(e) {
  return !!e && 1 === e.nodeType;
}
function Mi(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function Ai(e) {
  return !!e && 3 === e.nodeType;
}
function Li(e) {
  return !!e && 11 === e.nodeType;
}
function Di(e) {
  return e ? O(e).split(/\s+/) : [];
}
function Ni(e) {
  var i = null == t ? void 0 : t.location.href;
  return !!(i && e && e.some(e => i.match(e)));
}
function qi(e) {
  var t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return Di(t);
}
function Bi(e) {
  return J(e) ? null : O(e).split(/(\s+)/).filter(e => er(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function Hi(e) {
  var t = "";
  return Vi(e) && !Gi(e) && e.childNodes && e.childNodes.length && y(e.childNodes, function (e) {
    var i;
    Ai(e) && e.textContent && (t += null !== (i = Bi(e.textContent)) && void 0 !== i ? i : "");
  }), O(t);
}
function Ui(e) {
  return z(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
var ji = ["a", "button", "form", "input", "select", "textarea", "label"];
function zi(e) {
  var t = e.parentNode;
  return !(!t || !Oi(t)) && t;
}
function Wi(e, i) {
  var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    s = arguments.length > 3 ? arguments[3] : void 0,
    n = arguments.length > 4 ? arguments[4] : void 0;
  if (!t || !e || Mi(e, "html") || !Oi(e)) return !1;
  if (null != r && r.url_allowlist && !Ni(r.url_allowlist)) return !1;
  if (null != r && r.url_ignorelist && Ni(r.url_ignorelist)) return !1;
  if (null != r && r.dom_event_allowlist) {
    var o = r.dom_event_allowlist;
    if (o && !o.some(e => i.type === e)) return !1;
  }
  for (var a = !1, l = [e], c = !0, u = e; u.parentNode && !Mi(u, "body");) if (Li(u.parentNode)) l.push(u.parentNode.host), u = u.parentNode.host;else {
    if (!(c = zi(u))) break;
    if (s || ji.indexOf(c.tagName.toLowerCase()) > -1) a = !0;else {
      var d = t.getComputedStyle(c);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    l.push(c), u = c;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (z(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.tagName.toLowerCase() === t)) return {
        v: !0
      };
    };
    for (var s of e) {
      var n = r(s);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, r)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (z(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.matches(t))) return {
        v: !0
      };
    };
    for (var s of e) {
      var n = r(s);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, r)) return !1;
  var h = t.getComputedStyle(e);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === i.type) return !0;
  var p = e.tagName.toLowerCase();
  switch (p) {
    case "html":
      return !1;
    case "form":
      return (n || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (n || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return a ? (n || ["click"]).indexOf(i.type) >= 0 : (n || ["click"]).indexOf(i.type) >= 0 && (ji.indexOf(p) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function Vi(e) {
  for (var t = e; t.parentNode && !Mi(t, "body"); t = t.parentNode) {
    var i = qi(t);
    if ($(i, "ph-sensitive") || $(i, "ph-no-capture")) return !1;
  }
  if ($(qi(e), "ph-include")) return !0;
  var r = e.type || "";
  if (W(r)) switch (r.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var s = e.name || e.id || "";
  if (W(s)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(s.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function Gi(e) {
  return !!(Mi(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || Mi(e, "select") || Mi(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var Ji = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  Yi = new RegExp("^(?:".concat(Ji, ")$")),
  Ki = new RegExp(Ji),
  Xi = "\\d{3}-?\\d{2}-?\\d{4}",
  Qi = new RegExp("^(".concat(Xi, ")$")),
  Zi = new RegExp("(".concat(Xi, ")"));
function er(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (J(e)) return !1;
  if (W(e)) {
    if (e = O(e), (t ? Yi : Ki).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? Qi : Zi).test(e)) return !1;
  }
  return !0;
}
function tr(e) {
  var t = Hi(e);
  return er(t = "".concat(t, " ").concat(ir(e)).trim()) ? t : "";
}
function ir(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && y(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      var r = Hi(e);
      t = "".concat(t, " ").concat(r).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(ir(e)).trim());
    } catch (e) {
      te.error("[AutoCapture]", e);
    }
  }), t;
}
function rr(e) {
  return function (e) {
    var t = e.map(e => {
      var t,
        i,
        r = "";
      if (e.tag_name && (r += e.tag_name), e.attr_class) for (var s of (e.attr_class.sort(), e.attr_class)) r += ".".concat(s.replace(/"/g, ""));
      var n = oe(oe(oe(oe({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        o = {};
      return k(n).sort((e, t) => {
        var [i] = e,
          [r] = t;
        return i.localeCompare(r);
      }).forEach(e => {
        var [t, i] = e;
        return o[sr(t.toString())] = sr(i.toString());
      }), r += ":", r += k(n).map(e => {
        var [t, i] = e;
        return "".concat(t, '="').concat(i, '"');
      }).join("");
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t,
        i,
        r = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
          attr_class: nr(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return k(e).filter(e => {
        var [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        var [t, i] = e;
        return r.attributes[t] = i;
      }), r;
    });
  }(e));
}
function sr(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function nr(e) {
  var t = e.attr__class;
  return t ? B(t) ? t : Di(t) : void 0;
}
var or = "[SessionRecording]",
  ar = "redacted",
  lr = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io", ".clarity.ms", "analytics.google.com"]
  },
  cr = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  ur = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  dr = ["/s/", "/e/", "/i/"];
function hr(e, t, i, r) {
  if (J(e)) return e;
  var s = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return W(s) && (s = parseInt(s)), s > i ? or + " ".concat(r, " body too large to record (").concat(s, " bytes)") : e;
}
function pr(e, t) {
  if (J(e)) return e;
  var i = e;
  return er(i, !1) || (i = or + " " + t + " body " + ar), y(ur, e => {
    var r, s;
    null !== (r = i) && void 0 !== r && r.length && -1 !== (null === (s = i) || void 0 === s ? void 0 : s.indexOf(e)) && (i = or + " " + t + " body " + ar + " as might contain: " + e);
  }), i;
}
var _r = (e, t) => {
  var i,
    r,
    s,
    n = {
      payloadSizeLimitBytes: lr.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...lr.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...lr.payloadHostDenyList]
    },
    o = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    a = !1 !== e.session_recording.recordBody && t.recordBody,
    l = !1 !== e.capture_performance && t.recordPerformance,
    c = (i = n, s = Math.min(1e6, null !== (r = i.payloadSizeLimitBytes) && void 0 !== r ? r : 1e6), e => (null != e && e.requestBody && (e.requestBody = hr(e.requestBody, e.requestHeaders, s, "Request")), null != e && e.responseBody && (e.responseBody = hr(e.responseBody, e.responseHeaders, s, "Response")), e)),
    u = t => {
      return c(((e, t) => {
        var i,
          r = wt(e.name),
          s = 0 === t.indexOf("http") ? null === (i = wt(t)) || void 0 === i ? void 0 : i.pathname : t;
        "/" === s && (s = "");
        var n = null == r ? void 0 : r.pathname.replace(s || "", "");
        if (!(r && n && dr.some(e => 0 === n.indexOf(e)))) return e;
      })((r = (i = t).requestHeaders, J(r) || y(Object.keys(null != r ? r : {}), e => {
        cr.includes(e.toLowerCase()) && (r[e] = ar);
      }), i), e.api_host));
      var i, r;
    },
    d = H(e.session_recording.maskNetworkRequestFn);
  return d && H(e.session_recording.maskCapturedNetworkRequestFn) && te.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), d && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    var i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return oe(oe({}, t), {}, {
      name: null == i ? void 0 : i.url
    });
  }), n.maskRequestFn = H(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i,
      r,
      s,
      n = u(t);
    return n && null !== (i = null === (r = (s = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === r ? void 0 : r.call(s, n)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!z(e)) return e.requestBody = pr(e.requestBody, "Request"), e.responseBody = pr(e.responseBody, "Response"), e;
  }(u(e)), oe(oe(oe({}, lr), n), {}, {
    recordHeaders: o,
    recordBody: a,
    recordPerformance: l,
    recordInitialRequests: l
  });
};
function vr(e, t, i, r, s) {
  return t > i && (te.warn("min cannot be greater than max."), t = i), Y(e) ? e > i ? (r && te.warn(r + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (r && te.warn(r + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (r && te.warn(r + " must be a number. using max or fallback. max: " + i + ", fallback: " + s), vr(s || i, t, i, r));
}
class gr {
  constructor(e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    ae(this, "bucketSize", 100), ae(this, "refillRate", 10), ae(this, "mutationBuckets", {}), ae(this, "loggedTracker", {}), ae(this, "refillBuckets", () => {
      Object.keys(this.mutationBuckets).forEach(e => {
        this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
      });
    }), ae(this, "getNodeOrRelevantParent", e => {
      var t = this.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var i = t.closest("svg");
        if (i) return [this.rrweb.mirror.getId(i), i];
      }
      return [e, t];
    }), ae(this, "numberOfChanges", e => {
      var t, i, r, s, n, o, a, l;
      return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (r = null === (s = e.attributes) || void 0 === s ? void 0 : s.length) && void 0 !== r ? r : 0) + (null !== (n = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== n ? n : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
    }), ae(this, "throttleMutations", e => {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        i = this.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(e => {
        var t,
          i,
          r,
          [s, n] = this.getNodeOrRelevantParent(e.id);
        if (0 === this.mutationBuckets[s]) return !1;
        (this.mutationBuckets[s] = null !== (t = this.mutationBuckets[s]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[s] = Math.max(this.mutationBuckets[s] - 1, 0), 0 === this.mutationBuckets[s]) && (this.loggedTracker[s] || (this.loggedTracker[s] = !0, null === (i = (r = this.options).onBlockedNode) || void 0 === i || i.call(r, s, n)));
        return e;
      }));
      var r = this.numberOfChanges(t);
      return 0 !== r || i === r ? e : void 0;
    }), this.rrweb = e, this.options = r, this.refillRate = vr(null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, 0, 100, "mutation throttling refill rate"), this.bucketSize = vr(null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, 0, 100, "mutation throttling bucket size"), setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
}
var fr = Uint8Array,
  mr = Uint16Array,
  br = Uint32Array,
  yr = new fr([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  wr = new fr([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  Sr = new fr([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  kr = function (e, t) {
    for (var i = new mr(31), r = 0; r < 31; ++r) i[r] = t += 1 << e[r - 1];
    var s = new br(i[30]);
    for (r = 1; r < 30; ++r) for (var n = i[r]; n < i[r + 1]; ++n) s[n] = n - i[r] << 5 | r;
    return [i, s];
  },
  Er = kr(yr, 2),
  xr = Er[0],
  Ir = Er[1];
xr[28] = 258, Ir[258] = 28;
for (var Pr = kr(wr, 0)[1], Cr = new mr(32768), Fr = 0; Fr < 32768; ++Fr) {
  var Rr = (43690 & Fr) >>> 1 | (21845 & Fr) << 1;
  Rr = (61680 & (Rr = (52428 & Rr) >>> 2 | (13107 & Rr) << 2)) >>> 4 | (3855 & Rr) << 4, Cr[Fr] = ((65280 & Rr) >>> 8 | (255 & Rr) << 8) >>> 1;
}
var Tr = function (e, t, i) {
    for (var r = e.length, s = 0, n = new mr(t); s < r; ++s) ++n[e[s] - 1];
    var o,
      a = new mr(t);
    for (s = 0; s < t; ++s) a[s] = a[s - 1] + n[s - 1] << 1;
    if (i) {
      o = new mr(1 << t);
      var l = 15 - t;
      for (s = 0; s < r; ++s) if (e[s]) for (var c = s << 4 | e[s], u = t - e[s], d = a[e[s] - 1]++ << u, h = d | (1 << u) - 1; d <= h; ++d) o[Cr[d] >>> l] = c;
    } else for (o = new mr(r), s = 0; s < r; ++s) o[s] = Cr[a[e[s] - 1]++] >>> 15 - e[s];
    return o;
  },
  $r = new fr(288);
for (Fr = 0; Fr < 144; ++Fr) $r[Fr] = 8;
for (Fr = 144; Fr < 256; ++Fr) $r[Fr] = 9;
for (Fr = 256; Fr < 280; ++Fr) $r[Fr] = 7;
for (Fr = 280; Fr < 288; ++Fr) $r[Fr] = 8;
var Or = new fr(32);
for (Fr = 0; Fr < 32; ++Fr) Or[Fr] = 5;
var Mr = Tr($r, 9, 0),
  Ar = Tr(Or, 5, 0),
  Lr = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  Dr = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var r = new (e instanceof mr ? mr : e instanceof br ? br : fr)(i - t);
    return r.set(e.subarray(t, i)), r;
  },
  Nr = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8;
  },
  qr = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8, e[r + 2] |= i >>> 16;
  },
  Br = function (e, t) {
    for (var i = [], r = 0; r < e.length; ++r) e[r] && i.push({
      s: r,
      f: e[r]
    });
    var s = i.length,
      n = i.slice();
    if (!s) return [new fr(0), 0];
    if (1 == s) {
      var o = new fr(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      c = 0,
      u = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; u != s - 1;) a = i[i[c].f < i[d].f ? c++ : d++], l = i[c != u && i[c].f < i[d].f ? c++ : d++], i[u++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = n[0].s;
    for (r = 1; r < s; ++r) n[r].s > h && (h = n[r].s);
    var p = new mr(h + 1),
      _ = Hr(i[u - 1], p, 0);
    if (_ > t) {
      r = 0;
      var v = 0,
        g = _ - t,
        f = 1 << g;
      for (n.sort(function (e, t) {
        return p[t.s] - p[e.s] || e.f - t.f;
      }); r < s; ++r) {
        var m = n[r].s;
        if (!(p[m] > t)) break;
        v += f - (1 << _ - p[m]), p[m] = t;
      }
      for (v >>>= g; v > 0;) {
        var b = n[r].s;
        p[b] < t ? v -= 1 << t - p[b]++ - 1 : ++r;
      }
      for (; r >= 0 && v; --r) {
        var y = n[r].s;
        p[y] == t && (--p[y], ++v);
      }
      _ = t;
    }
    return [new fr(p), _];
  },
  Hr = function (e, t, i) {
    return -1 == e.s ? Math.max(Hr(e.l, t, i + 1), Hr(e.r, t, i + 1)) : t[e.s] = i;
  },
  Ur = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new mr(++t), r = 0, s = e[0], n = 1, o = function (e) {
        i[r++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == s && a != t) ++n;else {
      if (!s && n > 2) {
        for (; n > 138; n -= 138) o(32754);
        n > 2 && (o(n > 10 ? n - 11 << 5 | 28690 : n - 3 << 5 | 12305), n = 0);
      } else if (n > 3) {
        for (o(s), --n; n > 6; n -= 6) o(8304);
        n > 2 && (o(n - 3 << 5 | 8208), n = 0);
      }
      for (; n--;) o(s);
      n = 1, s = e[a];
    }
    return [i.subarray(0, r), t];
  },
  jr = function (e, t) {
    for (var i = 0, r = 0; r < t.length; ++r) i += e[r] * t[r];
    return i;
  },
  zr = function (e, t, i) {
    var r = i.length,
      s = Lr(t + 2);
    e[s] = 255 & r, e[s + 1] = r >>> 8, e[s + 2] = 255 ^ e[s], e[s + 3] = 255 ^ e[s + 1];
    for (var n = 0; n < r; ++n) e[s + n + 4] = i[n];
    return 8 * (s + 4 + r);
  },
  Wr = function (e, t, i, r, s, n, o, a, l, c, u) {
    Nr(t, u++, i), ++s[256];
    for (var d = Br(s, 15), h = d[0], p = d[1], _ = Br(n, 15), v = _[0], g = _[1], f = Ur(h), m = f[0], b = f[1], y = Ur(v), w = y[0], S = y[1], k = new mr(19), E = 0; E < m.length; ++E) k[31 & m[E]]++;
    for (E = 0; E < w.length; ++E) k[31 & w[E]]++;
    for (var x = Br(k, 7), I = x[0], P = x[1], C = 19; C > 4 && !I[Sr[C - 1]]; --C);
    var F,
      R,
      T,
      $,
      O = c + 5 << 3,
      M = jr(s, $r) + jr(n, Or) + o,
      A = jr(s, h) + jr(n, v) + o + 14 + 3 * C + jr(k, I) + (2 * k[16] + 3 * k[17] + 7 * k[18]);
    if (O <= M && O <= A) return zr(t, u, e.subarray(l, l + c));
    if (Nr(t, u, 1 + (A < M)), u += 2, A < M) {
      F = Tr(h, p, 0), R = h, T = Tr(v, g, 0), $ = v;
      var L = Tr(I, P, 0);
      Nr(t, u, b - 257), Nr(t, u + 5, S - 1), Nr(t, u + 10, C - 4), u += 14;
      for (E = 0; E < C; ++E) Nr(t, u + 3 * E, I[Sr[E]]);
      u += 3 * C;
      for (var D = [m, w], N = 0; N < 2; ++N) {
        var q = D[N];
        for (E = 0; E < q.length; ++E) {
          var B = 31 & q[E];
          Nr(t, u, L[B]), u += I[B], B > 15 && (Nr(t, u, q[E] >>> 5 & 127), u += q[E] >>> 12);
        }
      }
    } else F = Mr, R = $r, T = Ar, $ = Or;
    for (E = 0; E < a; ++E) if (r[E] > 255) {
      B = r[E] >>> 18 & 31;
      qr(t, u, F[B + 257]), u += R[B + 257], B > 7 && (Nr(t, u, r[E] >>> 23 & 31), u += yr[B]);
      var H = 31 & r[E];
      qr(t, u, T[H]), u += $[H], H > 3 && (qr(t, u, r[E] >>> 5 & 8191), u += wr[H]);
    } else qr(t, u, F[r[E]]), u += R[r[E]];
    return qr(t, u, F[256]), u + R[256];
  },
  Vr = new br([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Gr = function () {
    for (var e = new br(256), t = 0; t < 256; ++t) {
      for (var i = t, r = 9; --r;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  Jr = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, r = 0; r < t.length; ++r) i = Gr[255 & i ^ t[r]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  Yr = function (e, t, i, r, s) {
    return function (e, t, i, r, s, n) {
      var o = e.length,
        a = new fr(r + o + 5 * (1 + Math.floor(o / 7e3)) + s),
        l = a.subarray(r, a.length - s),
        c = 0;
      if (!t || o < 8) for (var u = 0; u <= o; u += 65535) {
        var d = u + 65535;
        d < o ? c = zr(l, c, e.subarray(u, d)) : (l[u] = n, c = zr(l, c, e.subarray(u, o)));
      } else {
        for (var h = Vr[t - 1], p = h >>> 13, _ = 8191 & h, v = (1 << i) - 1, g = new mr(32768), f = new mr(v + 1), m = Math.ceil(i / 3), b = 2 * m, y = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << b) & v;
          }, w = new br(25e3), S = new mr(288), k = new mr(32), E = 0, x = 0, I = (u = 0, 0), P = 0, C = 0; u < o; ++u) {
          var F = y(u),
            R = 32767 & u,
            T = f[F];
          if (g[R] = T, f[F] = R, P <= u) {
            var $ = o - u;
            if ((E > 7e3 || I > 24576) && $ > 423) {
              c = Wr(e, l, 0, w, S, k, x, I, C, u - C, c), I = E = x = 0, C = u;
              for (var O = 0; O < 286; ++O) S[O] = 0;
              for (O = 0; O < 30; ++O) k[O] = 0;
            }
            var M = 2,
              A = 0,
              L = _,
              D = R - T & 32767;
            if ($ > 2 && F == y(u - D)) for (var N = Math.min(p, $) - 1, q = Math.min(32767, u), B = Math.min(258, $); D <= q && --L && R != T;) {
              if (e[u + M] == e[u + M - D]) {
                for (var H = 0; H < B && e[u + H] == e[u + H - D]; ++H);
                if (H > M) {
                  if (M = H, A = D, H > N) break;
                  var U = Math.min(D, H - 2),
                    j = 0;
                  for (O = 0; O < U; ++O) {
                    var z = u - D + O + 32768 & 32767,
                      W = z - g[z] + 32768 & 32767;
                    W > j && (j = W, T = z);
                  }
                }
              }
              D += (R = T) - (T = g[R]) + 32768 & 32767;
            }
            if (A) {
              w[I++] = 268435456 | Ir[M] << 18 | Pr[A];
              var V = 31 & Ir[M],
                G = 31 & Pr[A];
              x += yr[V] + wr[G], ++S[257 + V], ++k[G], P = u + M, ++E;
            } else w[I++] = e[u], ++S[e[u]];
          }
        }
        c = Wr(e, l, n, w, S, k, x, I, C, u - C, c);
      }
      return Dr(a, 0, r + Lr(c) + s);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, r, !s);
  },
  Kr = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  Xr = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && Kr(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var r = 0; r <= i.length; ++r) e[r + 10] = i.charCodeAt(r);
    }
  },
  Qr = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function Zr(e, t) {
  void 0 === t && (t = {});
  var i = Jr(),
    r = e.length;
  i.p(e);
  var s = Yr(e, t, Qr(t), 8),
    n = s.length;
  return Xr(s, t), Kr(s, n - 8, i.d()), Kr(s, n - 4, r), s;
}
function es(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var r = new fr(e.length + (e.length >>> 1)), s = 0, n = function (e) {
      r[s++] = e;
    }, o = 0; o < i; ++o) {
    if (s + 5 > r.length) {
      var a = new fr(s + 8 + (i - o << 1));
      a.set(r), r = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? n(l) : l < 2048 ? (n(192 | l >>> 6), n(128 | 63 & l)) : l > 55295 && l < 57344 ? (n(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), n(128 | l >>> 12 & 63), n(128 | l >>> 6 & 63), n(128 | 63 & l)) : (n(224 | l >>> 12), n(128 | l >>> 6 & 63), n(128 | 63 & l));
  }
  return Dr(r, 0, s);
}
function ts(e, t) {
  return function (e) {
    for (var t = 0, i = 0; i < e.length; i++) t = (t << 5) - t + e.charCodeAt(i), t |= 0;
    return Math.abs(t);
  }(e) % 100 < vr(100 * t, 0, 100);
}
var is = "[SessionRecording]",
  rs = ie(is),
  ss = 3e5,
  ns = [Ti.MouseMove, Ti.MouseInteraction, Ti.Scroll, Ti.ViewportResize, Ti.Input, Ti.TouchMove, Ti.MediaInteraction, Ti.Drag],
  os = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  });
function as(e) {
  return function (e, t) {
    for (var i = "", r = 0; r < e.length;) {
      var s = e[r++];
      s < 128 || t ? i += String.fromCharCode(s) : s < 224 ? i += String.fromCharCode((31 & s) << 6 | 63 & e[r++]) : s < 240 ? i += String.fromCharCode((15 & s) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) : (s = ((15 & s) << 18 | (63 & e[r++]) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) - 65536, i += String.fromCharCode(55296 | s >> 10, 56320 | 1023 & s));
    }
    return i;
  }(Zr(es(JSON.stringify(e))), !0);
}
function ls(e) {
  return e.type === Ri.Custom && "sessionIdle" === e.data.tag;
}
function cs(e, t) {
  return t.some(t => "regex" === t.matching && new RegExp(t.url).test(e));
}
class us {
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get rrwebRecord() {
    var e, t;
    return null == p || null === (e = p.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(is + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.triggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : ss;
  }
  get isSampled() {
    var e = this.instance.get_property(Ie);
    return K(e) ? e : null;
  }
  get sessionDuration() {
    var e,
      t,
      i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: r
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - r : null;
  }
  get isRecordingEnabled() {
    var e = !!this.instance.get_property(me),
      i = !this.instance.config.disable_session_recording;
    return t && e && i;
  }
  get isConsoleLogCaptureEnabled() {
    var e = !!this.instance.get_property(be),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e,
      t,
      i,
      r,
      s,
      n,
      o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(we),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      c = null !== (i = null !== (r = null == o ? void 0 : o.canvasFps) && void 0 !== r ? r : null == a ? void 0 : a.fps) && void 0 !== i ? i : 4,
      u = null !== (s = null !== (n = null == o ? void 0 : o.canvasQuality) && void 0 !== n ? n : null == a ? void 0 : a.quality) && void 0 !== s ? s : .4;
    if ("string" == typeof u) {
      var d = parseFloat(u);
      u = isNaN(d) ? .4 : d;
    }
    return {
      enabled: l,
      fps: vr(c, 0, 12, "canvas recording fps", 4),
      quality: vr(u, 0, 1, "canvas recording quality", .4)
    };
  }
  get networkPayloadCapture() {
    var e,
      t,
      i = this.instance.get_property(ye),
      r = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      s = (null == r ? void 0 : r.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      n = (null == r ? void 0 : r.recordBody) || (null == i ? void 0 : i.recordBody),
      o = U(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(K(o) ? o : null == i ? void 0 : i.capturePerformance);
    return s || n || a ? {
      recordHeaders: s,
      recordBody: n,
      recordPerformance: a
    } : void 0;
  }
  get sampleRate() {
    var e = this.instance.get_property(Se);
    return Y(e) ? e : null;
  }
  get minimumDuration() {
    var e = this.instance.get_property(ke);
    return Y(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? !1 === this.isSampled ? "disabled" : this._urlBlocked ? "paused" : J(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.triggerStatus ? "buffering" : K(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e;
    return 0 === this._urlTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Pe)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get eventTriggerStatus() {
    var e;
    return 0 === this._eventTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ce)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get triggerStatus() {
    var e = "trigger_activated" === this.eventTriggerStatus || "trigger_activated" === this.urlTriggerStatus,
      t = "trigger_pending" === this.eventTriggerStatus || "trigger_pending" === this.urlTriggerStatus;
    return e ? "trigger_activated" : t ? "trigger_pending" : "trigger_disabled";
  }
  constructor(e) {
    if (ae(this, "queuedRRWebEvents", []), ae(this, "isIdle", !1), ae(this, "_linkedFlagSeen", !1), ae(this, "_lastActivityTimestamp", Date.now()), ae(this, "_linkedFlag", null), ae(this, "_removePageViewCaptureHook", void 0), ae(this, "_onSessionIdListener", void 0), ae(this, "_persistDecideOnSessionListener", void 0), ae(this, "_samplingSessionListener", void 0), ae(this, "_urlTriggers", []), ae(this, "_urlBlocklist", []), ae(this, "_urlBlocked", !1), ae(this, "_eventTriggers", []), ae(this, "_removeEventTriggerCaptureHook", void 0), ae(this, "_forceAllowLocalhostNetworkCapture", !1), ae(this, "_onBeforeUnload", () => {
      this._flushBuffer();
    }), ae(this, "_onOffline", () => {
      this._tryAddCustomEvent("browser offline", {});
    }), ae(this, "_onOnline", () => {
      this._tryAddCustomEvent("browser online", {});
    }), ae(this, "_onVisibilityChange", () => {
      if (null != a && a.visibilityState) {
        var e = "window " + a.visibilityState;
        this._tryAddCustomEvent(e, {});
      }
    }), this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw rs.error("started without valid sessionManager"), new Error(is + " started without valid sessionManager. This is a bug.");
    if (this.instance.config.__preview_experimental_cookieless_mode) throw new Error(is + " cannot be used with __preview_experimental_cookieless_mode.");
    var {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && rs.warn("session_idle_threshold_ms (".concat(this.sessionIdleThresholdMilliseconds, ") is greater than the session timeout (").concat(this.sessionManager.sessionTimeoutMs, "). Session will never be detected as idle"));
  }
  startIfEnabledOrStop(e) {
    this.isRecordingEnabled ? (this._startCapture(e), T(t, "beforeunload", this._onBeforeUnload), T(t, "offline", this._onOffline), T(t, "online", this._onOnline), T(t, "visibilitychange", this._onVisibilityChange), this._setupSampling(), this._addEventTriggerListener(), J(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        if ("$pageview" === e.event) {
          var t = null != e && e.properties.$current_url ? this._maskUrl(null == e ? void 0 : e.properties.$current_url) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        rs.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var r, s, n, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (r = this.instance) || void 0 === r || null === (s = r.persistence) || void 0 === s || s.unregister(Ce), null === (n = this.instance) || void 0 === n || null === (o = n.persistence) || void 0 === o || o.unregister(Pe));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var e, i, r, s;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == t || t.removeEventListener("beforeunload", this._onBeforeUnload), null == t || t.removeEventListener("offline", this._onOffline), null == t || t.removeEventListener("online", this._onOnline), null == t || t.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (e = this._removePageViewCaptureHook) || void 0 === e || e.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._removeEventTriggerCaptureHook) || void 0 === i || i.call(this), this._removeEventTriggerCaptureHook = void 0, null === (r = this._onSessionIdListener) || void 0 === r || r.call(this), this._onSessionIdListener = void 0, null === (s = this._samplingSessionListener) || void 0 === s || s.call(this), this._samplingSessionListener = void 0, rs.info("stopped"));
  }
  makeSamplingDecision(e) {
    var t,
      i = this.sessionId !== e,
      r = this.sampleRate;
    if (Y(r)) {
      var s = this.isSampled,
        n = i || !K(s),
        o = n ? ts(e, r) : s;
      n && (o ? this._reportStarted("sampled") : rs.warn("Sample rate (".concat(r, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
        sampleRate: r,
        isSampled: o
      })), null === (t = this.instance.persistence) || void 0 === t || t.register({
        [Ie]: o
      });
    } else {
      var a;
      null === (a = this.instance.persistence) || void 0 === a || a.register({
        [Ie]: null
      });
    }
  }
  onRemoteConfig(e) {
    var t, i, r, s, n, o;
    (this._tryAddCustomEvent("$remote_config_received", e), this._persistRemoteConfig(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.endpoint);
    if (this._setupSampling(), !J(this._linkedFlag) && !this._linkedFlagSeen) {
      var a = W(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        l = W(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((e, t) => {
        var i = U(t) && a in t,
          r = l ? t[a] === l : i;
        r && this._reportStarted("linked_flag_matched", {
          linkedFlag: a,
          linkedVariant: l
        }), this._linkedFlagSeen = r;
      });
    }
    null !== (r = e.sessionRecording) && void 0 !== r && r.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), null !== (s = e.sessionRecording) && void 0 !== s && s.urlBlocklist && (this._urlBlocklist = e.sessionRecording.urlBlocklist), null !== (n = e.sessionRecording) && void 0 !== n && n.eventTriggers && (this._eventTriggers = e.sessionRecording.eventTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    Y(this.sampleRate) && J(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistRemoteConfig(e) {
    if (this.instance.persistence) {
      var t,
        i = this.instance.persistence,
        r = () => {
          var t,
            r,
            s,
            n,
            o,
            a,
            l,
            c,
            u = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            d = J(u) ? null : parseFloat(u),
            h = null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.minimumDurationMilliseconds;
          i.register({
            [me]: !!e.sessionRecording,
            [be]: null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.consoleLogRecordingEnabled,
            [ye]: oe({
              capturePerformance: e.capturePerformance
            }, null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.networkPayloadCapture),
            [we]: {
              enabled: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.recordCanvas,
              fps: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.canvasFps,
              quality: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasQuality
            },
            [Se]: d,
            [ke]: z(h) ? null : h,
            [Ee]: null === (c = e.sessionRecording) || void 0 === c ? void 0 : c.scriptConfig
          });
        };
      r(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(r);
    }
  }
  log(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!z(Object.assign) && !z(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), this.rrwebRecord) this._onScriptLoaded();else null === (t = p.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, this.scriptName, e => {
        if (e) return rs.error("could not load recorder", e);
        this._onScriptLoaded();
      });
      rs.info("starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  get scriptName() {
    var e, t, i;
    return (null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t || null === (i = t.get_property(Ee)) || void 0 === i ? void 0 : i.script) || "recorder";
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== ns.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    var t = this.isInteractiveEvent(e);
    t || this.isIdle || e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
      eventTimestamp: e.timestamp,
      lastActivityTimestamp: this._lastActivityTimestamp,
      threshold: this.sessionIdleThresholdMilliseconds,
      bufferLength: this.buffer.data.length,
      bufferSize: this.buffer.size
    }), this._flushBuffer());
    var i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
      reason: "user activity",
      type: e.type
    }), i = !0)), !this.isIdle) {
      var {
          windowId: r,
          sessionId: s
        } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
        n = this.sessionId !== s,
        o = this.windowId !== r;
      this.windowId = r, this.sessionId = s, n || o ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
    }
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : rs.warn("could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(os(() => this.rrwebRecord.addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(os(() => this.rrwebRecord.takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e,
      t = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      i = this.instance.config.session_recording;
    for (var [r, s] of Object.entries(i || {})) r in t && ("maskInputOptions" === r ? t.maskInputOptions = oe({
      password: !0
    }, s) : t[r] = s);
    if (this.canvasRecording && this.canvasRecording.enabled && (t.recordCanvas = !0, t.sampling = {
      canvas: this.canvasRecording.fps
    }, t.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), this.rrwebRecord) {
      this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new gr(this.rrwebRecord, {
        refillRate: this.instance.config.session_recording.__mutationRateLimiterRefillRate,
        bucketSize: this.instance.config.session_recording.__mutationRateLimiterBucketSize,
        onBlockedNode: (e, t) => {
          var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
          rs.info(i, {
            node: t
          }), this.log(is + " " + i, "warn");
        }
      });
      var n = this._gatherRRWebPlugins();
      this.stopRrweb = this.rrwebRecord(oe({
        emit: e => {
          this.onRRwebEmit(e);
        },
        plugins: n
      }, t)), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
        sessionRecordingOptions: t,
        activePlugins: n.map(e => null == e ? void 0 : e.name)
      }), this._tryAddCustomEvent("$posthog_config", {
        config: this.instance.config
      });
    } else rs.error("onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), !this.isIdle) {
      var e = this.fullSnapshotIntervalMillis;
      e && (this._fullSnapshotTimer = setInterval(() => {
        this._tryTakeFullSnapshot();
      }, e));
    }
  }
  _gatherRRWebPlugins() {
    var e,
      t,
      i,
      r,
      s = [],
      n = null === (e = p.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    n && this.isConsoleLogCaptureEnabled && s.push(n());
    var o = null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.rrwebPlugins) || void 0 === r ? void 0 : r.getRecordNetworkPlugin;
    this.networkPayloadCapture && H(o) && (!yt.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? s.push(o(_r(this.instance.config, this.networkPayloadCapture))) : rs.info("NetworkCapture not started because we are on localhost."));
    return s;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), e && U(e)) {
      if (e.type === Ri.Meta) {
        var i = this._maskUrl(e.data.href);
        if (this._lastHref = i, !i) return;
        e.data.href = i;
      } else this._pageViewFallBack();
      if (this._checkUrlTriggerConditions(), !this._urlBlocked || function (e) {
        return e.type === Ri.Custom && "recording paused" === e.data.tag;
      }(e)) {
        e.type === Ri.FullSnapshot && this._scheduleFullSnapshot(), e.type === Ri.FullSnapshot && "trigger_pending" === this.triggerStatus && this.clearBuffer();
        var r = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
        if (r) {
          var s = function (e) {
            var t = e;
            if (t && U(t) && 6 === t.type && U(t.data) && "rrweb/console@1" === t.data.plugin) {
              t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
              for (var i = [], r = 0; r < t.data.payload.payload.length; r++) t.data.payload.payload[r] && t.data.payload.payload[r].length > 2e3 ? i.push(t.data.payload.payload[r].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[r]);
              return t.data.payload.payload = i, e;
            }
            return e;
          }(r);
          if (this._updateWindowAndSessionIds(s), !this.isIdle || ls(s)) {
            if (ls(s)) {
              var n = s.data.payload;
              if (n) {
                var o = n.lastActivityTimestamp,
                  a = n.threshold;
                s.timestamp = o + a;
              }
            }
            var l = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
                if (Ci(e) < 1024) return e;
                try {
                  if (e.type === Ri.FullSnapshot) return oe(oe({}, e), {}, {
                    data: as(e.data),
                    cv: "2024-10"
                  });
                  if (e.type === Ri.IncrementalSnapshot && e.data.source === Ti.Mutation) return oe(oe({}, e), {}, {
                    cv: "2024-10",
                    data: oe(oe({}, e.data), {}, {
                      texts: as(e.data.texts),
                      attributes: as(e.data.attributes),
                      removes: as(e.data.removes),
                      adds: as(e.data.adds)
                    })
                  });
                  if (e.type === Ri.IncrementalSnapshot && e.data.source === Ti.StyleSheetRule) return oe(oe({}, e), {}, {
                    cv: "2024-10",
                    data: oe(oe({}, e.data), {}, {
                      adds: e.data.adds ? as(e.data.adds) : void 0,
                      removes: e.data.removes ? as(e.data.removes) : void 0
                    })
                  });
                } catch (e) {
                  rs.error("could not compress event - will use uncompressed event", e);
                }
                return e;
              }(s) : s,
              c = {
                $snapshot_bytes: Ci(l),
                $snapshot_data: l,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
            "disabled" !== this.status ? this._captureSnapshotBuffered(c) : this.clearBuffer();
          }
        }
      }
    }
  }
  _pageViewFallBack() {
    if (!this.instance.config.capture_pageview && t) {
      var e = this._maskUrl(t.location.href);
      this._lastHref !== e && (this._tryAddCustomEvent("$url_changed", {
        href: e
      }), this._lastHref = e);
    }
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      var e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    var t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i,
        r = {
          url: e
        };
      return null === (i = r = t.maskNetworkRequestFn(r)) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    var e = this.minimumDuration,
      t = this.sessionDuration,
      i = Y(t) && t >= 0,
      r = Y(e) && i && t < e;
    if ("buffering" === this.status || "paused" === this.status || "disabled" === this.status || r) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    this.buffer.data.length > 0 && Fi(this.buffer).forEach(e => {
      this._captureSnapshot({
        $snapshot_bytes: e.size,
        $snapshot_data: e.data,
        $session_id: e.sessionId,
        $window_id: e.windowId,
        $lib: "web",
        $lib_version: _.LIB_VERSION
      });
    });
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t,
      i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTriggerConditions() {
    if (void 0 !== t && t.location.href) {
      var e = t.location.href,
        i = this._urlBlocked,
        r = cs(e, this._urlBlocklist);
      r && !i ? this._pauseRecording() : !r && i && this._resumeRecording(), cs(e, this._urlTriggers) && this._activateTrigger("url");
    }
  }
  _activateTrigger(e) {
    var t, i;
    "trigger_pending" === this.triggerStatus && (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      ["url" === e ? Pe : Ce]: this.sessionId
    }), this._flushBuffer(), this._reportStarted(e + "_trigger_matched"));
  }
  _pauseRecording() {
    this._urlBlocked || (this._urlBlocked = !0, clearInterval(this._fullSnapshotTimer), rs.info("recording paused due to URL blocker"), this._tryAddCustomEvent("recording paused", {
      reason: "url blocker"
    }));
  }
  _resumeRecording() {
    this._urlBlocked && (this._urlBlocked = !1, this._tryTakeFullSnapshot(), this._scheduleFullSnapshot(), this._tryAddCustomEvent("recording resumed", {
      reason: "left blocked url"
    }), rs.info("recording resumed"));
  }
  _addEventTriggerListener() {
    0 !== this._eventTriggers.length && J(this._removeEventTriggerCaptureHook) && (this._removeEventTriggerCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        this._eventTriggers.includes(e.event) && this._activateTrigger("event");
      } catch (e) {
        rs.error("Could not activate event trigger", e);
      }
    }));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._tryTakeFullSnapshot(), this._reportStarted("linked_flag_overridden");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [Ie]: !0
    }), this._tryTakeFullSnapshot(), this._reportStarted("sampling_overridden");
  }
  overrideTrigger(e) {
    this._activateTrigger(e);
  }
  _reportStarted(e, t) {
    this.instance.register_for_session({
      $session_recording_start_reason: e
    }), rs.info(e.replace("_", " "), t), $(["recording_initialized", "session_id_changed"], e) || this._tryAddCustomEvent(e, t);
  }
}
var ds = ie("[RemoteConfig]");
class hs {
  constructor(e) {
    this.instance = e;
  }
  get remoteConfig() {
    var e, t;
    return null === (e = p._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.config;
  }
  _loadRemoteConfigJs(e) {
    var t, i, r;
    null !== (t = p.__PosthogExtensions__) && void 0 !== t && t.loadExternalDependency ? null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "remote-config", () => e(this.remoteConfig)) : (ds.error("PostHog Extensions not found. Cannot load remote config."), e());
  }
  _loadRemoteConfigJSON(e) {
    this.instance._send_request({
      method: "GET",
      url: this.instance.requestRouter.endpointFor("assets", "/array/".concat(this.instance.config.token, "/config")),
      callback: t => {
        e(t.json);
      }
    });
  }
  load() {
    try {
      if (this.remoteConfig) return ds.info("Using preloaded remote config", this.remoteConfig), void this.onRemoteConfig(this.remoteConfig);
      if (this.instance.config.advanced_disable_decide) return void ds.warn("Remote config is disabled. Falling back to local config.");
      this._loadRemoteConfigJs(e => {
        if (!e) return ds.info("No config found after loading remote JS config. Falling back to JSON."), void this._loadRemoteConfigJSON(e => {
          this.onRemoteConfig(e);
        });
        this.onRemoteConfig(e);
      });
    } catch (e) {
      ds.error("Error loading remote config", e);
    }
  }
  onRemoteConfig(e) {
    e ? this.instance.config.__preview_remote_config ? (this.instance._onRemoteConfig(e), !1 !== e.hasFeatureFlags && this.instance.featureFlags.ensureFlagsLoaded()) : ds.info("__preview_remote_config is disabled. Logging config instead", e) : ds.error("Failed to fetch remote config from PostHog.");
  }
}
var ps,
  _s = null != t && t.location ? xt(t.location.hash, "__posthog") || xt(location.hash, "state") : null,
  vs = "_postHogToolbarParams",
  gs = ie("[Toolbar]");
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(ps || (ps = {}));
class fs {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    p.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = p.ph_toolbar_state) && void 0 !== e ? e : ps.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var e,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!t || !a) return !1;
    r = null !== (e = r) && void 0 !== e ? e : t.location, n = null !== (i = n) && void 0 !== i ? i : t.history;
    try {
      if (!s) {
        try {
          t.localStorage.setItem("test", "test"), t.localStorage.removeItem("test");
        } catch (e) {
          return !1;
        }
        s = null == t ? void 0 : t.localStorage;
      }
      var o,
        l = _s || xt(r.hash, "__posthog") || xt(r.hash, "state"),
        c = l ? E(() => JSON.parse(atob(decodeURIComponent(l)))) || E(() => JSON.parse(decodeURIComponent(l))) : null;
      return c && "ph_authorize" === c.action ? ((o = c).source = "url", o && Object.keys(o).length > 0 && (c.desiredHash ? r.hash = c.desiredHash : n ? n.replaceState(n.state, "", r.pathname + r.search) : r.hash = "")) : ((o = JSON.parse(s.getItem(vs) || "{}")).source = "localstorage", delete o.userIntent), !(!o.token || this.instance.config.token !== o.token) && (this.loadToolbar(o), !0);
    } catch (e) {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    var t = p.ph_load_toolbar || p.ph_load_editor;
    !J(t) && H(t) ? t(e, this.instance) : gs.warn("No toolbar load function found");
  }
  loadToolbar(e) {
    var i = !(null == a || !a.getElementById(je));
    if (!t || i) return !1;
    var r = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      s = oe(oe({
        token: this.instance.config.token
      }, e), {}, {
        apiURL: this.instance.requestRouter.endpointFor("ui")
      }, r ? {
        instrument: !1
      } : {});
    if (t.localStorage.setItem(vs, JSON.stringify(oe(oe({}, s), {}, {
      source: void 0
    }))), this.getToolbarState() === ps.LOADED) this._callLoadToolbar(s);else if (this.getToolbarState() === ps.UNINITIALIZED) {
      var n, o;
      this.setToolbarState(ps.LOADING), null === (n = p.__PosthogExtensions__) || void 0 === n || null === (o = n.loadExternalDependency) || void 0 === o || o.call(n, this.instance, "toolbar", e => {
        if (e) return gs.error("[Toolbar] Failed to load", e), void this.setToolbarState(ps.UNINITIALIZED);
        this.setToolbarState(ps.LOADED), this._callLoadToolbar(s);
      }), T(t, "turbolinks:load", () => {
        this.setToolbarState(ps.UNINITIALIZED), this.loadToolbar(s);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
var ms = 3e3;
class bs {
  constructor(e, t) {
    ae(this, "isPaused", !0), ae(this, "queue", []), this.flushTimeoutMs = vr((null == t ? void 0 : t.flush_interval_ms) || ms, 250, 5e3, "flush interval", ms), this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    var e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest(oe(oe({}, e), {}, {
        transport: "sendBeacon"
      }));
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    var e = this;
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        var t = this.formatQueue(),
          i = function (i) {
            var r = t[i],
              s = new Date().getTime();
            r.data && B(r.data) && y(r.data, e => {
              e.offset = Math.abs(e.timestamp - s), delete e.timestamp;
            }), e.sendRequest(r);
          };
        for (var r in t) i(r);
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    var e = {};
    return y(this.queue, t => {
      var i,
        r = t,
        s = (r ? r.batchKey : null) || r.url;
      z(e[s]) && (e[s] = oe(oe({}, r), {}, {
        data: []
      })), null === (i = e[s].data) || void 0 === i || i.push(r.data);
    }), this.queue = [], e;
  }
}
var ys = function (e) {
    var t,
      i,
      r,
      s,
      n = "";
    for (t = i = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, s = 0; s < r; s++) {
      var o = e.charCodeAt(s),
        a = null;
      o < 128 ? i++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), G(a) || (i > t && (n += e.substring(t, i)), n += a, t = i = s + 1);
    }
    return i > t && (n += e.substring(t, e.length)), n;
  },
  ws = !!u || !!c,
  Ss = "text/plain",
  ks = (e, t) => {
    var [i, r] = e.split("?"),
      s = oe({}, t);
    null == r || r.split("&").forEach(e => {
      var [t] = e.split("=");
      delete s[t];
    });
    var n = St(s);
    return n = n ? (r ? r + "&" : "") + n : r, "".concat(i, "?").concat(n);
  },
  Es = (e, t) => JSON.stringify(e, (e, t) => "bigint" == typeof t ? t.toString() : t, t),
  xs = t => {
    var {
      data: i,
      compression: r
    } = t;
    if (i) {
      if (r === e.GZipJS) {
        var s = Zr(es(Es(i)), {
            mtime: 0
          }),
          n = new Blob([s], {
            type: Ss
          });
        return {
          contentType: Ss,
          body: n,
          estimatedSize: n.size
        };
      }
      if (r === e.Base64) {
        var o = function (e) {
            var t,
              i,
              r,
              s,
              n,
              o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
              a = 0,
              l = 0,
              c = "",
              u = [];
            if (!e) return e;
            e = ys(e);
            do {
              t = (n = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, i = n >> 12 & 63, r = n >> 6 & 63, s = 63 & n, u[l++] = o.charAt(t) + o.charAt(i) + o.charAt(r) + o.charAt(s);
            } while (a < e.length);
            switch (c = u.join(""), e.length % 3) {
              case 1:
                c = c.slice(0, -2) + "==";
                break;
              case 2:
                c = c.slice(0, -1) + "=";
            }
            return c;
          }(Es(i)),
          a = (e => "data=" + encodeURIComponent("string" == typeof e ? e : Es(e)))(o);
        return {
          contentType: "application/x-www-form-urlencoded",
          body: a,
          estimatedSize: new Blob([a]).size
        };
      }
      var l = Es(i);
      return {
        contentType: "application/json",
        body: l,
        estimatedSize: new Blob([l]).size
      };
    }
  },
  Is = [];
c && Is.push({
  transport: "fetch",
  method: e => {
    var t,
      i,
      {
        contentType: r,
        body: s,
        estimatedSize: n
      } = null !== (t = xs(e)) && void 0 !== t ? t : {},
      o = new Headers();
    y(e.headers, function (e, t) {
      o.append(t, e);
    }), r && o.append("Content-Type", r);
    var a = e.url,
      l = null;
    if (d) {
      var u = new d();
      l = {
        signal: u.signal,
        timeout: setTimeout(() => u.abort(), e.timeout)
      };
    }
    c(a, oe({
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (n || 0) < 52428.8,
      body: s,
      signal: null === (i = l) || void 0 === i ? void 0 : i.signal
    }, e.fetchOptions)).then(t => t.text().then(i => {
      var r,
        s = {
          statusCode: t.status,
          text: i
        };
      if (200 === t.status) try {
        s.json = JSON.parse(i);
      } catch (e) {
        te.error(e);
      }
      null === (r = e.callback) || void 0 === r || r.call(e, s);
    })).catch(t => {
      var i;
      te.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => l ? clearTimeout(l.timeout) : null);
  }
}), u && Is.push({
  transport: "XHR",
  method: e => {
    var t,
      i = new u();
    i.open(e.method || "GET", e.url, !0);
    var {
      contentType: r,
      body: s
    } = null !== (t = xs(e)) && void 0 !== t ? t : {};
    y(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), r && i.setRequestHeader("Content-Type", r), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t,
          r = {
            statusCode: i.status,
            text: i.responseText
          };
        if (200 === i.status) try {
          r.json = JSON.parse(i.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, r);
      }
    }, i.send(s);
  }
}), null != o && o.sendBeacon && Is.push({
  transport: "sendBeacon",
  method: e => {
    var t = ks(e.url, {
      beacon: "1"
    });
    try {
      var i,
        {
          contentType: r,
          body: s
        } = null !== (i = xs(e)) && void 0 !== i ? i : {},
        n = "string" == typeof s ? new Blob([s], {
          type: r
        }) : s;
      o.sendBeacon(t, n);
    } catch (e) {}
  }
});
var Ps = ["retriesPerformedSoFar"];
class Cs {
  constructor(e) {
    ae(this, "isPolling", !1), ae(this, "pollIntervalMs", 3e3), ae(this, "queue", []), this.instance = e, this.queue = [], this.areWeOnline = !0, !z(t) && "onLine" in t.navigator && (this.areWeOnline = t.navigator.onLine, T(t, "online", () => {
      this.areWeOnline = !0, this.flush();
    }), T(t, "offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    var {
        retriesPerformedSoFar: t
      } = e,
      i = le(e, Ps);
    Y(t) && t > 0 && (i.url = ks(i.url, {
      retry_count: t
    })), this.instance._send_request(oe(oe({}, i), {}, {
      callback: e => {
        var r;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue(oe({
          retriesPerformedSoFar: t
        }, i)) : null === (r = i.callback) || void 0 === r || r.call(i, e);
      }
    }));
  }
  enqueue(e) {
    var t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    var i = function (e) {
        var t = 3e3 * Math.pow(2, e),
          i = t / 2,
          r = Math.min(18e5, t),
          s = (Math.random() - .5) * (r - i);
        return Math.ceil(r + s);
      }(t),
      r = Date.now() + i;
    this.queue.push({
      retryAt: r,
      requestOptions: e
    });
    var s = "Enqueued failed request for retry in ".concat(i);
    navigator.onLine || (s += " (Browser is offline)"), te.warn(s), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    var e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (var {
      requestOptions: r
    } of i) this.retriableRequest(r);
  }
  unload() {
    for (var {
      requestOptions: e
    } of (this.poller && (clearTimeout(this.poller), this.poller = void 0), this.queue)) try {
      this.instance._send_request(oe(oe({}, e), {}, {
        transport: "sendBeacon"
      }));
    } catch (e) {
      te.error(e);
    }
    this.queue = [];
  }
}
var Fs,
  Rs = ie("[SessionId]");
class Ts {
  constructor(e, t, i) {
    var r;
    if (ae(this, "_sessionIdChangedHandlers", []), !e.persistence) throw new Error("SessionIdManager requires a PostHogPersistence instance");
    if (e.config.__preview_experimental_cookieless_mode) throw new Error("SessionIdManager cannot be used with __preview_experimental_cookieless_mode");
    this.config = e.config, this.persistence = e.persistence, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = t || nt, this._windowIdGenerator = i || nt;
    var s = this.config.persistence_name || this.config.token,
      n = this.config.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * vr(n, 60, 36e3, "session_idle_timeout_seconds", 1800), e.register({
      $configured_session_timeout_ms: this._sessionTimeoutMs
    }), this.resetIdleTimer(), this._window_id_storage_key = "ph_" + s + "_window_id", this._primary_window_exists_storage_key = "ph_" + s + "_primary_window_exists", this._canUseSessionStorage()) {
      var o = bt.parse(this._window_id_storage_key),
        a = bt.parse(this._primary_window_exists_storage_key);
      o && !a ? this._windowId = o : bt.remove(this._window_id_storage_key), bt.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (r = this.config.bootstrap) && void 0 !== r && r.sessionID) try {
      var l = (e => {
        var t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), l);
    } catch (e) {
      Rs.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return z(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && bt.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && bt.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? bt.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [xe]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    var e = this.persistence.props[xe];
    return B(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    T(t, "beforeunload", () => {
      this._canUseSessionStorage() && bt.remove(this._primary_window_exists_storage_key);
    }, {
      capture: !1
    });
  }
  checkAndGetSessionAndWindowId() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
    if (this.config.__preview_experimental_cookieless_mode) throw new Error("checkAndGetSessionAndWindowId should not be called in __preview_experimental_cookieless_mode");
    var i = t || new Date().getTime(),
      [r, s, n] = this._getSessionId(),
      o = this._getWindowId(),
      a = Y(n) && n > 0 && Math.abs(i - n) > 864e5,
      l = !1,
      c = !s,
      u = !e && Math.abs(i - r) > this.sessionTimeoutMs;
    c || u || a ? (s = this._sessionIdGenerator(), o = this._windowIdGenerator(), Rs.info("new session ID generated", {
      sessionId: s,
      windowId: o,
      changeReason: {
        noSessionId: c,
        activityTimeout: u,
        sessionPastMaximumLength: a
      }
    }), n = i, l = !0) : o || (o = this._windowIdGenerator(), l = !0);
    var d = 0 === r || !e || a ? i : r,
      h = 0 === n ? new Date().getTime() : n;
    return this._setWindowId(o), this._setSessionId(s, d, h), e || this.resetIdleTimer(), l && this._sessionIdChangedHandlers.forEach(e => e(s, o, l ? {
      noSessionId: c,
      activityTimeout: u,
      sessionPastMaximumLength: a
    } : void 0)), {
      sessionId: s,
      windowId: o,
      sessionStartTimestamp: h,
      changeReason: l ? {
        noSessionId: c,
        activityTimeout: u,
        sessionPastMaximumLength: a
      } : void 0,
      lastActivityTimestamp: r
    };
  }
  resetIdleTimer() {
    clearTimeout(this._enforceIdleTimeout), this._enforceIdleTimeout = setTimeout(() => {
      this.resetSessionId();
    }, 1.1 * this.sessionTimeoutMs);
  }
}
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(Fs || (Fs = {}));
var $s = "i.posthog.com";
class Os {
  constructor(e) {
    ae(this, "_regionCache", {}), this.instance = e;
  }
  get apiHost() {
    var e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e,
      t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(".".concat($s), ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = Fs.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = Fs.EU : this._regionCache[this.apiHost] = Fs.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return this.uiHost + t;
    if (this.region === Fs.CUSTOM) return this.apiHost + t;
    var i = $s + t;
    switch (e) {
      case "assets":
        return "https://".concat(this.region, "-assets.").concat(i);
      case "api":
        return "https://".concat(this.region, ".").concat(i);
    }
  }
}
var Ms = "posthog-js";
function As(e) {
  var {
    organization: t,
    projectId: i,
    prefix: r,
    severityAllowList: s = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return n => {
    var o, a, l, c, u;
    if (!("*" === s || s.includes(n.level)) || !e.__loaded) return n;
    n.tags || (n.tags = {});
    var d = e.requestRouter.endpointFor("ui", "/project/".concat(e.config.token, "/person/").concat(e.get_distinct_id()));
    n.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (n.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    var h = (null === (o = n.exception) || void 0 === o ? void 0 : o.values) || [];
    h.forEach(e => {
      e.stacktrace && (e.stacktrace.type = "raw", e.stacktrace.frames.forEach(e => {
        e.platform = "web:javascript";
      }));
    });
    var p = {
      $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || n.message,
      $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
      $exception_personURL: d,
      $exception_level: n.level,
      $exception_list: h,
      $sentry_event_id: n.event_id,
      $sentry_exception: n.exception,
      $sentry_exception_message: (null === (c = h[0]) || void 0 === c ? void 0 : c.value) || n.message,
      $sentry_exception_type: null === (u = h[0]) || void 0 === u ? void 0 : u.type,
      $sentry_tags: n.tags
    };
    return t && i && (p.$sentry_url = (r || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + n.event_id), e.exceptions.sendExceptionEvent(p), n;
  };
}
class Ls {
  constructor(e, t, i, r, s) {
    this.name = Ms, this.setupOnce = function (n) {
      n(As(e, {
        organization: t,
        projectId: i,
        prefix: r,
        severityAllowList: s
      }));
    };
  }
}
var Ds = ie("[SegmentIntegration]");
function Ns(e, t) {
  var i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    var i = e.config.segment;
    if (!i) return t();
    var r = i => {
        var r = () => i.anonymousId() || nt();
        e.config.get_device_id = r, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: r()
        }), e.persistence.set_property(Le, "identified")), t();
      },
      s = i.user();
    "then" in s && H(s.then) ? s.then(e => r(e)) : r(s);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || Ds.warn("This browser does not have Promise support, and can not use the segment integration");
      var t = (t, i) => {
        var r;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || (Ds.info("No userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && (Ds.info("UserId set, identifying with PostHog"), e.identify(t.event.userId));
        var s = e._calculate_event_properties(i, null !== (r = t.event.properties) && void 0 !== r ? r : {}, new Date());
        return t.event.properties = Object.assign({}, s, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
class qs {
  constructor(e) {
    this._instance = e;
  }
  doPageView(e, i) {
    var r,
      s = this._previousPageViewProperties(e, i);
    return this._currentPageview = {
      pathname: null !== (r = null == t ? void 0 : t.location.pathname) && void 0 !== r ? r : "",
      pageViewId: i,
      timestamp: e
    }, this._instance.scrollManager.resetContext(), s;
  }
  doPageLeave(e) {
    var t;
    return this._previousPageViewProperties(e, null === (t = this._currentPageview) || void 0 === t ? void 0 : t.pageViewId);
  }
  doEvent() {
    var e;
    return {
      $pageview_id: null === (e = this._currentPageview) || void 0 === e ? void 0 : e.pageViewId
    };
  }
  _previousPageViewProperties(e, t) {
    var i = this._currentPageview;
    if (!i) return {
      $pageview_id: t
    };
    var r = {
        $pageview_id: t,
        $prev_pageview_id: i.pageViewId
      },
      s = this._instance.scrollManager.getContext();
    if (s && !this._instance.config.disable_scroll_properties) {
      var {
        maxScrollHeight: n,
        lastScrollY: o,
        maxScrollY: a,
        maxContentHeight: l,
        lastContentY: c,
        maxContentY: u
      } = s;
      if (!(z(n) || z(o) || z(a) || z(l) || z(c) || z(u))) {
        n = Math.ceil(n), o = Math.ceil(o), a = Math.ceil(a), l = Math.ceil(l), c = Math.ceil(c), u = Math.ceil(u);
        var d = n <= 1 ? 1 : vr(o / n, 0, 1),
          h = n <= 1 ? 1 : vr(a / n, 0, 1),
          p = l <= 1 ? 1 : vr(c / l, 0, 1),
          _ = l <= 1 ? 1 : vr(u / l, 0, 1);
        r = w(r, {
          $prev_pageview_last_scroll: o,
          $prev_pageview_last_scroll_percentage: d,
          $prev_pageview_max_scroll: a,
          $prev_pageview_max_scroll_percentage: h,
          $prev_pageview_last_content: c,
          $prev_pageview_last_content_percentage: p,
          $prev_pageview_max_content: u,
          $prev_pageview_max_content_percentage: _
        });
      }
    }
    return i.pathname && (r.$prev_pageview_pathname = i.pathname), i.timestamp && (r.$prev_pageview_duration = (e.getTime() - i.timestamp.getTime()) / 1e3), r;
  }
}
var Bs,
  Hs,
  Us,
  js,
  zs,
  Ws,
  Vs,
  Gs,
  Js = {},
  Ys = [],
  Ks = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  Xs = Array.isArray;
function Qs(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function Zs(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function en(e, t, i, r, s) {
  var n = {
    type: e,
    props: t,
    key: i,
    ref: r,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == s ? ++Us : s,
    __i: -1,
    __u: 0
  };
  return null == s && null != Hs.vnode && Hs.vnode(n), n;
}
function tn(e) {
  return e.children;
}
function rn(e, t) {
  this.props = e, this.context = t;
}
function sn(e, t) {
  if (null == t) return e.__ ? sn(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? sn(e) : null;
}
function nn(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return nn(e);
  }
}
function on(e) {
  (!e.__d && (e.__d = !0) && js.push(e) && !an.__r++ || zs !== Hs.debounceRendering) && ((zs = Hs.debounceRendering) || Ws)(an);
}
function an() {
  var e, t, i, r, s, n, o, a, l;
  for (js.sort(Vs); e = js.shift();) e.__d && (t = js.length, r = void 0, n = (s = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((r = Qs({}, s)).__v = s.__v + 1, Hs.vnode && Hs.vnode(r), gn(o, r, s, i.__n, void 0 !== o.ownerSVGElement, 32 & s.__u ? [n] : null, a, null == n ? sn(s) : n, !!(32 & s.__u), l), r.__.__k[r.__i] = r, fn(a, r, l), r.__e != n && nn(r)), js.length > t && js.sort(Vs));
  an.__r = 0;
}
function ln(e, t, i, r, s, n, o, a, l, c, u) {
  var d,
    h,
    p,
    _,
    v,
    g = r && r.__k || Ys,
    f = t.length;
  for (i.__d = l, cn(i, t, g), l = i.__d, d = 0; d < f; d++) null != (p = i.__k[d]) && "boolean" != typeof p && "function" != typeof p && (h = -1 === p.__i ? Js : g[p.__i] || Js, p.__i = d, gn(e, p, h, s, n, o, a, l, c, u), _ = p.__e, p.ref && h.ref != p.ref && (h.ref && bn(h.ref, null, p), u.push(p.ref, p.__c || _, p)), null == v && null != _ && (v = _), 65536 & p.__u || h.__k === p.__k ? l = un(p, l, e) : "function" == typeof p.type && void 0 !== p.__d ? l = p.__d : _ && (l = _.nextSibling), p.__d = void 0, p.__u &= -196609);
  i.__d = l, i.__e = v;
}
function cn(e, t, i) {
  var r,
    s,
    n,
    o,
    a,
    l = t.length,
    c = i.length,
    u = c,
    d = 0;
  for (e.__k = [], r = 0; r < l; r++) null != (s = e.__k[r] = null == (s = t[r]) || "boolean" == typeof s || "function" == typeof s ? null : "string" == typeof s || "number" == typeof s || "bigint" == typeof s || s.constructor == String ? en(null, s, null, null, s) : Xs(s) ? en(tn, {
    children: s
  }, null, null, null) : void 0 === s.constructor && s.__b > 0 ? en(s.type, s.props, s.key, s.ref ? s.ref : null, s.__v) : s) ? (s.__ = e, s.__b = e.__b + 1, a = dn(s, i, o = r + d, u), s.__i = a, n = null, -1 !== a && (u--, (n = i[a]) && (n.__u |= 131072)), null == n || null === n.__v ? (-1 == a && d--, "function" != typeof s.type && (s.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? u > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== r + d && (s.__u |= 65536))) : (n = i[r]) && null == n.key && n.__e && (n.__e == e.__d && (e.__d = sn(n)), yn(n, n, !1), i[r] = null, u--);
  if (u) for (r = 0; r < c; r++) null != (n = i[r]) && 0 == (131072 & n.__u) && (n.__e == e.__d && (e.__d = sn(n)), yn(n, n));
}
function un(e, t, i) {
  var r, s;
  if ("function" == typeof e.type) {
    for (r = e.__k, s = 0; r && s < r.length; s++) r[s] && (r[s].__ = e, t = un(r[s], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function dn(e, t, i, r) {
  var s = e.key,
    n = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && s == l.key && n === l.type) return i;
  if (r > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && s == l.key && n === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && s == l.key && n === l.type) return a;
      a++;
    }
  }
  return -1;
}
function hn(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || Ks.test(t) ? i : i + "px";
}
function pn(e, t, i, r, s) {
  var n;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof r && (e.style.cssText = r = ""), r) for (t in r) i && t in i || hn(e.style, t, "");
      if (i) for (t in i) r && i[t] === r[t] || hn(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) n = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + n] = i, i ? r ? i.u = r.u : (i.u = Date.now(), e.addEventListener(t, n ? vn : _n, n)) : e.removeEventListener(t, n ? vn : _n, n);else {
    if (s) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function _n(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(Hs.event ? Hs.event(e) : e);
}
function vn(e) {
  return this.l[e.type + !0](Hs.event ? Hs.event(e) : e);
}
function gn(e, t, i, r, s, n, o, a, l, c) {
  var u,
    d,
    h,
    p,
    _,
    v,
    g,
    f,
    m,
    b,
    y,
    w,
    S,
    k,
    E,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), n = [a = t.__e = i.__e]), (u = Hs.__b) && u(t);
  e: if ("function" == typeof x) try {
    if (f = t.props, m = (u = x.contextType) && r[u.__c], b = u ? m ? m.props.value : u.__ : r, i.__c ? g = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(f, b) : (t.__c = d = new rn(f, b), d.constructor = x, d.render = wn), m && m.sub(d), d.props = f, d.state || (d.state = {}), d.context = b, d.__n = r, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = Qs({}, d.__s)), Qs(d.__s, x.getDerivedStateFromProps(f, d.__s))), p = d.props, _ = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && f !== p && null != d.componentWillReceiveProps && d.componentWillReceiveProps(f, b), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(f, d.__s, b) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = f, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), y = 0; y < d._sb.length; y++) d.__h.push(d._sb[y]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(f, d.__s, b), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(p, _, v);
      });
    }
    if (d.context = b, d.props = f, d.__P = e, d.__e = !1, w = Hs.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), u = d.render(d.props, d.state, d.context), k = 0; k < d._sb.length; k++) d.__h.push(d._sb[k]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), u = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (r = Qs(Qs({}, r), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (v = d.getSnapshotBeforeUpdate(p, _)), ln(e, Xs(E = null != u && u.type === tn && null == u.key ? u.props.children : u) ? E : [E], t, i, r, s, n, o, a, l, c), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), g && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != n ? (t.__e = a, t.__u |= l ? 160 : 32, n[n.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), Hs.__e(e, t, i);
  } else null == n && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = mn(i.__e, t, i, r, s, n, o, l, c);
  (u = Hs.diffed) && u(t);
}
function fn(e, t, i) {
  t.__d = void 0;
  for (var r = 0; r < i.length; r++) bn(i[r], i[++r], i[++r]);
  Hs.__c && Hs.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      Hs.__e(e, t.__v);
    }
  });
}
function mn(e, t, i, r, s, n, o, a, l) {
  var c,
    u,
    d,
    h,
    p,
    _,
    v,
    g = i.props,
    f = t.props,
    m = t.type;
  if ("svg" === m && (s = !0), null != n) for (c = 0; c < n.length; c++) if ((p = n[c]) && "setAttribute" in p == !!m && (m ? p.localName === m : 3 === p.nodeType)) {
    e = p, n[c] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(f);
    e = s ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, f.is && f), n = null, a = !1;
  }
  if (null === m) g === f || a && e.data === f || (e.data = f);else {
    if (n = n && Bs.call(e.childNodes), g = i.props || Js, !a && null != n) for (g = {}, c = 0; c < e.attributes.length; c++) g[(p = e.attributes[c]).name] = p.value;
    for (c in g) p = g[c], "children" == c || ("dangerouslySetInnerHTML" == c ? d = p : "key" === c || c in f || pn(e, c, null, p, s));
    for (c in f) p = f[c], "children" == c ? h = p : "dangerouslySetInnerHTML" == c ? u = p : "value" == c ? _ = p : "checked" == c ? v = p : "key" === c || a && "function" != typeof p || g[c] === p || pn(e, c, p, g[c], s);
    if (u) a || d && (u.__html === d.__html || u.__html === e.innerHTML) || (e.innerHTML = u.__html), t.__k = [];else if (d && (e.innerHTML = ""), ln(e, Xs(h) ? h : [h], t, i, r, s && "foreignObject" !== m, n, o, n ? n[0] : i.__k && sn(i, 0), a, l), null != n) for (c = n.length; c--;) null != n[c] && Zs(n[c]);
    a || (c = "value", void 0 !== _ && (_ !== e[c] || "progress" === m && !_ || "option" === m && _ !== g[c]) && pn(e, c, _, g[c], !1), c = "checked", void 0 !== v && v !== e[c] && pn(e, c, v, g[c], !1));
  }
  return e;
}
function bn(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    Hs.__e(e, i);
  }
}
function yn(e, t, i) {
  var r, s;
  if (Hs.unmount && Hs.unmount(e), (r = e.ref) && (r.current && r.current !== e.__e || bn(r, null, t)), null != (r = e.__c)) {
    if (r.componentWillUnmount) try {
      r.componentWillUnmount();
    } catch (e) {
      Hs.__e(e, t);
    }
    r.base = r.__P = null, e.__c = void 0;
  }
  if (r = e.__k) for (s = 0; s < r.length; s++) r[s] && yn(r[s], t, i || "function" != typeof e.type);
  i || null == e.__e || Zs(e.__e), e.__ = e.__e = e.__d = void 0;
}
function wn(e, t, i) {
  return this.constructor(e, i);
}
Bs = Ys.slice, Hs = {
  __e: function (e, t, i, r) {
    for (var s, n, o; t = t.__;) if ((s = t.__c) && !s.__) try {
      if ((n = s.constructor) && null != n.getDerivedStateFromError && (s.setState(n.getDerivedStateFromError(e)), o = s.__d), null != s.componentDidCatch && (s.componentDidCatch(e, r || {}), o = s.__d), o) return s.__E = s;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Us = 0, rn.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = Qs({}, this.state), "function" == typeof e && (e = e(Qs({}, i), this.props)), e && Qs(i, e), null != e && this.__v && (t && this._sb.push(t), on(this));
}, rn.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), on(this));
}, rn.prototype.render = tn, js = [], Ws = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, Vs = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, an.__r = 0, Gs = 0;
var Sn, kn, En;
!function (e, t) {
  var i = {
    __c: t = "__cC" + Gs++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, r;
      return this.getChildContext || (i = [], (r = {})[t] = this, this.getChildContext = function () {
        return r;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, on(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  handleCloseSurveyPopup: () => {},
  isPopup: !0,
  onPreviewSubmit: () => {}
}), function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(Sn || (Sn = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(kn || (kn = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(En || (En = {}));
class xn {
  constructor() {
    ae(this, "events", {}), this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (var i of this.events[e] || []) i(t);
    for (var r of this.events["*"] || []) r(e, t);
  }
}
class In {
  constructor(e) {
    ae(this, "_debugEventEmitter", new xn()), ae(this, "checkStep", (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t)), ae(this, "checkStepEvent", (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event)), this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!z(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.on(e, t);
      });
    }
  }
  register(e) {
    var t, i;
    if (!z(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var r,
        s = new Set();
      e.forEach(e => {
        var t;
        null === (t = e.steps) || void 0 === t || t.forEach(e => {
          null != e && e.selector && s.add(null == e ? void 0 : e.selector);
        });
      }), null === (r = this.instance) || void 0 === r || r.autocapture.setElementSelectors(s);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (var i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!r || "string" != typeof r) return !1;
      if (!In.matchString(r, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(e, i, r) {
    switch (r) {
      case "regex":
        return !!t && A(e, i);
      case "exact":
        return i === e;
      case "contains":
        var s = In.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return A(e, s);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if ((null != t && t.href || null != t && t.tag_name || null != t && t.text) && !this.getElementsList(e).some(e => !(null != t && t.href && !In.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !In.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !In.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    if (null != t && t.selector) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!r) return !1;
      if (!r.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class Pn {
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    z(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    var t = e.filter(e => {
      var t, i, r, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (r = i.actions) || void 0 === r || null === (s = r.values) || void 0 === s ? void 0 : s.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new In(this.instance), this.actionMatcher.init();
        this.actionMatcher._addActionHook(e => {
          this.onAction(e);
        });
      }
      t.forEach(e => {
        var t, i, r, s, n, o, a, l, c, u;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (r = i.actions) && void 0 !== r && r.values && (null === (s = e.conditions) || void 0 === s || null === (n = s.actions) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c || null === (u = c.values) || void 0 === u || u.forEach(t => {
          if (t && t.name) {
            var i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 !== e.filter(e => {
      var t, i, r, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r || null === (s = r.values) || void 0 === s ? void 0 : s.length) > 0;
    }).length) {
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.onEvent(e, t);
      }), e.forEach(e => {
        var t, i, r;
        null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (r = i.values) || void 0 === r || r.forEach(t => {
          if (t && t.name) {
            var i = this.eventToSurveys.get(t.name);
            i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
          }
        });
      });
    }
  }
  onEvent(e, t) {
    var i,
      r,
      s = (null === (i = this.instance) || void 0 === i || null === (r = i.persistence) || void 0 === r ? void 0 : r.props[Me]) || [];
    if (Pn.SURVEY_SHOWN_EVENT_NAME == e && t && s.length > 0) {
      var n,
        o = null == t || null === (n = t.properties) || void 0 === n ? void 0 : n.$survey_id;
      if (o) {
        var a = s.indexOf(o);
        a >= 0 && (s.splice(a, 1), this._updateActivatedSurveys(s));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(s.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t,
      i,
      r = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[Me]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(r.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [Me]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e,
      t,
      i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[Me];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
ae(Pn, "SURVEY_SHOWN_EVENT_NAME", "survey shown");
var Cn = ie("[Surveys]"),
  Fn = {
    icontains: (e, t) => e.some(e => t.toLowerCase().includes(e.toLowerCase())),
    not_icontains: (e, t) => e.every(e => !t.toLowerCase().includes(e.toLowerCase())),
    regex: (e, t) => e.some(e => A(t, e)),
    not_regex: (e, t) => e.every(e => !A(t, e)),
    exact: (e, t) => e.some(e => t === e),
    is_not: (e, t) => e.every(e => t !== e)
  };
function Rn(e, t, i) {
  var r,
    s = e.questions[t],
    n = t + 1;
  if (null === (r = s.branching) || void 0 === r || !r.type) return t === e.questions.length - 1 ? En.End : n;
  if (s.branching.type === En.End) return En.End;
  if (s.branching.type === En.SpecificQuestion) {
    if (Number.isInteger(s.branching.index)) return s.branching.index;
  } else if (s.branching.type === En.ResponseBased) {
    if (s.type === kn.SingleChoice) {
      var o,
        a,
        l = s.choices.indexOf("".concat(i));
      if (null !== (o = s.branching) && void 0 !== o && null !== (a = o.responseValues) && void 0 !== a && a.hasOwnProperty(l)) {
        var c = s.branching.responseValues[l];
        return Number.isInteger(c) ? c : c === En.End ? En.End : n;
      }
    } else if (s.type === kn.Rating) {
      var u, d;
      if ("number" != typeof i || !Number.isInteger(i)) throw new Error("The response type must be an integer");
      var h = function (e, t) {
        if (3 === t) {
          if (e < 1 || e > 3) throw new Error("The response must be in range 1-3");
          return 1 === e ? "negative" : 2 === e ? "neutral" : "positive";
        }
        if (5 === t) {
          if (e < 1 || e > 5) throw new Error("The response must be in range 1-5");
          return e <= 2 ? "negative" : 3 === e ? "neutral" : "positive";
        }
        if (7 === t) {
          if (e < 1 || e > 7) throw new Error("The response must be in range 1-7");
          return e <= 3 ? "negative" : 4 === e ? "neutral" : "positive";
        }
        if (10 === t) {
          if (e < 0 || e > 10) throw new Error("The response must be in range 0-10");
          return e <= 6 ? "detractors" : e <= 8 ? "passives" : "promoters";
        }
        throw new Error("The scale must be one of: 3, 5, 7, 10");
      }(i, s.scale);
      if (null !== (u = s.branching) && void 0 !== u && null !== (d = u.responseValues) && void 0 !== d && d.hasOwnProperty(h)) {
        var p = s.branching.responseValues[h];
        return Number.isInteger(p) ? p : p === En.End ? En.End : n;
      }
    }
    return n;
  }
  return Cn.warn("Falling back to next question index due to unexpected branching type"), n;
}
function Tn(e) {
  return null != e ? e : "icontains";
}
class $n {
  constructor(e) {
    ae(this, "getNextSurveyStep", Rn), this.instance = e, this._surveyEventReceiver = null;
  }
  onRemoteConfig(e) {
    this._decideServerResponse = !!e.surveys, Cn.info("decideServerResponse set to ".concat(this._decideServerResponse)), this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    var e = (() => {
      for (var e = [], t = 0; t < localStorage.length; t++) {
        var i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    if (!this._surveyManager) if (this.instance.config.disable_surveys) Cn.info("Disabled. Not loading surveys.");else {
      var e = null == p ? void 0 : p.__PosthogExtensions__;
      if (e) {
        var t = e.generateSurveys;
        if (this._decideServerResponse) {
          if (null == this._surveyEventReceiver && (this._surveyEventReceiver = new Pn(this.instance)), t) this._surveyManager = t(this.instance);else {
            var i = e.loadExternalDependency;
            i ? i(this.instance, "surveys", t => {
              var i;
              t ? Cn.error("Could not load surveys script", t) : this._surveyManager = null === (i = e.generateSurveys) || void 0 === i ? void 0 : i.call(e, this.instance);
            }) : Cn.error("PostHog loadExternalDependency extension not found. Cannot load remote config.");
          }
        } else Cn.warn("Decide not loaded yet. Not loading surveys.");
      } else Cn.error("PostHog Extensions not found.");
    }
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return Cn.info("Disabled. Not loading surveys."), e([]);
    null == this._surveyEventReceiver && (this._surveyEventReceiver = new Pn(this.instance));
    var i = this.instance.get_property(Oe);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        var i,
          r = t.statusCode;
        if (200 !== r || !t.json) return Cn.error("Surveys API could not be loaded, status: ".concat(r)), e([]);
        var s,
          n = t.json.surveys || [],
          o = n.filter(e => {
            var t, i, r, s, n, o, a, l, c, u, d, h;
            return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r ? void 0 : r.values) && (null === (s = e.conditions) || void 0 === s || null === (n = s.events) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c ? void 0 : c.values) && (null === (u = e.conditions) || void 0 === u || null === (d = u.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
          });
        o.length > 0 && (null === (s = this._surveyEventReceiver) || void 0 === s || s.register(o));
        return null === (i = this.instance.persistence) || void 0 === i || i.register({
          [Oe]: n
        }), e(n);
      }
    });
  }
  isSurveyFeatureFlagEnabled(e) {
    return !e || this.instance.featureFlags.isFeatureEnabled(e);
  }
  getActiveMatchingSurveys(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(i => {
      var r,
        s = i.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var i;
          if (!e.conditions) return !0;
          var r = function (e) {
              var i, r, s;
              if (null === (i = e.conditions) || void 0 === i || !i.url) return !0;
              var n = null == t || null === (r = t.location) || void 0 === r ? void 0 : r.href;
              if (!n) return !1;
              var o = [e.conditions.url];
              return Fn[Tn(null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType)](o, n);
            }(e),
            s = null === (i = e.conditions) || void 0 === i || !i.selector || (null == a ? void 0 : a.querySelector(e.conditions.selector)),
            n = function (e) {
              var t, i, r;
              if (null === (t = e.conditions) || void 0 === t || !t.deviceTypes || 0 === (null === (i = e.conditions) || void 0 === i ? void 0 : i.deviceTypes.length)) return !0;
              if (!h) return !1;
              var s = xi.deviceType(h);
              return Fn[Tn(null === (r = e.conditions) || void 0 === r ? void 0 : r.deviceTypesMatchType)](e.conditions.deviceTypes, s);
            }(e);
          return r && s && n;
        }),
        n = null === (r = this._surveyEventReceiver) || void 0 === r ? void 0 : r.getSurveys(),
        o = s.filter(e => {
          var t, i, r, s, o, a, l, c, u;
          if (!(e.linked_flag_key || e.targeting_flag_key || e.internal_targeting_flag_key || null !== (t = e.feature_flag_keys) && void 0 !== t && t.length)) return !0;
          var d = this.isSurveyFeatureFlagEnabled(e.linked_flag_key),
            h = this.isSurveyFeatureFlagEnabled(e.targeting_flag_key),
            p = (null !== (i = null === (r = e.conditions) || void 0 === r || null === (s = r.events) || void 0 === s || null === (o = s.values) || void 0 === o ? void 0 : o.length) && void 0 !== i ? i : 0) > 0,
            _ = (null !== (a = null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c || null === (u = c.values) || void 0 === u ? void 0 : u.length) && void 0 !== a ? a : 0) > 0,
            v = !p && !_ || (null == n ? void 0 : n.includes(e.id)),
            g = this._canActivateRepeatedly(e) || this.isSurveyFeatureFlagEnabled(e.internal_targeting_flag_key),
            f = this.checkFlags(e);
          return d && h && g && v && f;
        });
      return e(o);
    }, i);
  }
  checkFlags(e) {
    var t;
    return null === (t = e.feature_flag_keys) || void 0 === t || !t.length || e.feature_flag_keys.every(e => {
      var {
        key: t,
        value: i
      } = e;
      return !t || !i || this.instance.featureFlags.isFeatureEnabled(i);
    });
  }
  _canActivateRepeatedly(e) {
    var t;
    return J(null === (t = p.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? (Cn.warn("init was not called"), !1) : p.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    J(this._surveyManager) ? Cn.warn("init was not called") : this.getSurveys(t => {
      var i = t.filter(t => t.id === e)[0];
      this._surveyManager.canRenderSurvey(i);
    });
  }
  renderSurvey(e, t) {
    J(this._surveyManager) ? Cn.warn("init was not called") : this.getSurveys(i => {
      var r = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(r, null == a ? void 0 : a.querySelector(t));
    });
  }
}
var On = ie("[RateLimiter]");
class Mn {
  constructor(e) {
    var t, i;
    ae(this, "serverLimits", {}), ae(this, "lastEventRateLimited", !1), ae(this, "checkForLimiting", e => {
      var t = e.text;
      if (t && t.length) try {
        (JSON.parse(t).quota_limited || []).forEach(e => {
          On.info("".concat(e || "events", " is quota limited.")), this.serverLimits[e] = new Date().getTime() + 6e4;
        });
      } catch (e) {
        return void On.warn('could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
          text: t
        });
      }
    }), this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e,
      t,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      s = new Date().getTime(),
      n = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property(Ne)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: s
      };
    n.tokens += (s - n.last) / 1e3 * this.captureEventsPerSecond, n.last = s, n.tokens > this.captureEventsBurstLimit && (n.tokens = this.captureEventsBurstLimit);
    var o = n.tokens < 1;
    return o || r || (n.tokens = Math.max(0, n.tokens - 1)), !o || this.lastEventRateLimited || r || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: "posthog-js client rate limited. Config is set to ".concat(this.captureEventsPerSecond, " events per second and ").concat(this.captureEventsBurstLimit, " events burst limit.")
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property(Ne, n), {
      isRateLimited: o,
      remainingTokens: n.tokens
    };
  }
  isServerRateLimited(e) {
    var t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
}
var An = e => xi.personInfo({
  maskPersonalDataProperties: null == e ? void 0 : e.config.mask_personal_data_properties,
  customPersonalDataProperties: null == e ? void 0 : e.config.custom_personal_data_properties
});
class Ln {
  constructor(e, t, i, r) {
    ae(this, "_onSessionIdCallback", e => {
      var t = this._getStored();
      if (!t || t.sessionId !== e) {
        var i = {
          sessionId: e,
          props: this._sessionSourceParamGenerator(this.instance)
        };
        this._persistence.register({
          [De]: i
        });
      }
    }), this.instance = e, this._sessionIdManager = t, this._persistence = i, this._sessionSourceParamGenerator = r || An, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStored() {
    return this._persistence.props[De];
  }
  getSetOnceInitialSessionPropsProps() {
    var e,
      t = null === (e = this._getStored()) || void 0 === e ? void 0 : e.props;
    return t ? "r" in t ? xi.personPropsFromInfo(t) : {
      $referring_domain: t.referringDomain,
      $pathname: t.initialPathName,
      utm_source: t.utm_source,
      utm_campaign: t.utm_campaign,
      utm_medium: t.utm_medium,
      utm_content: t.utm_content,
      utm_term: t.utm_term
    } : {};
  }
}
var Dn = ["ahrefsbot", "ahrefssiteaudit", "applebot", "baiduspider", "better uptime bot", "bingbot", "bingpreview", "bot.htm", "bot.php", "crawler", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "semrushbot", "sitebulb", "slurp", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "gptbot", "oai-searchbot", "chatgpt-user", "headlesschrome", "cypress", "Google-HotelAdsVerifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "Bytespider;"],
  Nn = function (e, t) {
    if (!e) return !1;
    var i = e.toLowerCase();
    return Dn.concat(t || []).some(e => {
      var t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  qn = function (e, t) {
    if (!e) return !1;
    var i = e.userAgent;
    if (i && Nn(i, t)) return !0;
    try {
      var r = null == e ? void 0 : e.userAgentData;
      if (null != r && r.brands && r.brands.some(e => Nn(null == e ? void 0 : e.brand, t))) return !0;
    } catch (e) {}
    return !!e.webdriver;
  };
class Bn {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    var r = this.clicks[this.clicks.length - 1];
    if (r && Math.abs(e - r.x) + Math.abs(t - r.y) < 30 && i - r.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
var Hn = ie("[Dead Clicks]"),
  Un = () => !0,
  jn = e => {
    var t,
      i = !(null === (t = e.instance.persistence) || void 0 === t || !t.get_property(ge)),
      r = e.instance.config.capture_dead_clicks;
    return K(r) ? r : i;
  };
class zn {
  get lazyLoadedDeadClicksAutocapture() {
    return this._lazyLoadedDeadClicksAutocapture;
  }
  constructor(e, t, i) {
    this.instance = e, this.isEnabled = t, this.onCapture = i, this.startIfEnabled();
  }
  onRemoteConfig(e) {
    this.instance.persistence && this.instance.persistence.register({
      [ge]: null == e ? void 0 : e.captureDeadClicks
    }), this.startIfEnabled();
  }
  startIfEnabled() {
    this.isEnabled(this) && this.loadScript(() => {
      this.start();
    });
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = p.__PosthogExtensions__) && void 0 !== t && t.initDeadClicksAutocapture && e(), null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "dead-clicks-autocapture", t => {
      t ? Hn.error("failed to load script", t) : e();
    });
  }
  start() {
    var e;
    if (a) {
      if (!this._lazyLoadedDeadClicksAutocapture && null !== (e = p.__PosthogExtensions__) && void 0 !== e && e.initDeadClicksAutocapture) {
        var t = U(this.instance.config.capture_dead_clicks) ? this.instance.config.capture_dead_clicks : {};
        t.__onCapture = this.onCapture, this._lazyLoadedDeadClicksAutocapture = p.__PosthogExtensions__.initDeadClicksAutocapture(this.instance, t), this._lazyLoadedDeadClicksAutocapture.start(a), Hn.info("starting...");
      }
    } else Hn.error("`document` not found. Cannot start.");
  }
  stop() {
    this._lazyLoadedDeadClicksAutocapture && (this._lazyLoadedDeadClicksAutocapture.stop(), this._lazyLoadedDeadClicksAutocapture = void 0, Hn.info("stopping..."));
  }
}
var Wn = ie("[Heatmaps]");
function Vn(e) {
  return U(e) && "clientX" in e && "clientY" in e && Y(e.clientX) && Y(e.clientY);
}
class Gn {
  constructor(e) {
    var t;
    ae(this, "rageclicks", new Bn()), ae(this, "_enabledServerSide", !1), ae(this, "_initialized", !1), ae(this, "_flushInterval", null), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[pe]);
  }
  get flushIntervalMilliseconds() {
    var e = 5e3;
    return U(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return z(this.instance.config.capture_heatmaps) ? z(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      Wn.info("starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e, t;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), null === (t = this.deadClicksCapture) || void 0 === t || t.stop(), this.getAndClearBuffer();
    }
  }
  onRemoteConfig(e) {
    var t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [pe]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    var e = this.buffer;
    return this.buffer = void 0, e;
  }
  _onDeadClick(e) {
    this._onClick(e.originalEvent, "deadclick");
  }
  _setupListeners() {
    t && a && (T(t, "beforeunload", this.flush.bind(this)), T(a, "click", e => this._onClick(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), T(a, "mousemove", e => this._onMouseMove(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), this.deadClicksCapture = new zn(this.instance, Un, this._onDeadClick.bind(this)), this.deadClicksCapture.startIfEnabled(), this._initialized = !0);
  }
  _getProperties(e, i) {
    var r = this.instance.scrollManager.scrollY(),
      s = this.instance.scrollManager.scrollX(),
      n = this.instance.scrollManager.scrollElement(),
      o = function (e, i, r) {
        for (var s = e; s && Oi(s) && !Mi(s, "body");) {
          if (s === r) return !1;
          if ($(i, null == t ? void 0 : t.getComputedStyle(s).position)) return !0;
          s = zi(s);
        }
        return !1;
      }(Ui(e), ["fixed", "sticky"], n);
    return {
      x: e.clientX + (o ? 0 : s),
      y: e.clientY + (o ? 0 : r),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "click";
    if (!$i(e.target) && Vn(e)) {
      var r = this._getProperties(e, i);
      null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture(oe(oe({}, r), {}, {
        type: "rageclick"
      })), this._capture(r);
    }
  }
  _onMouseMove(e) {
    !$i(e.target) && Vn(e) && (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(e) {
    if (t) {
      var i = t.location.href;
      this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(e);
    }
  }
  flush() {
    this.buffer && !j(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class Jn {
  constructor(e) {
    ae(this, "_updateScrollData", () => {
      var e, t, i, r;
      this.context || (this.context = {});
      var s = this.scrollElement(),
        n = this.scrollY(),
        o = s ? Math.max(0, s.scrollHeight - s.clientHeight) : 0,
        a = n + ((null == s ? void 0 : s.clientHeight) || 0),
        l = (null == s ? void 0 : s.scrollHeight) || 0;
      this.context.lastScrollY = Math.ceil(n), this.context.maxScrollY = Math.max(n, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (r = this.context.maxContentHeight) && void 0 !== r ? r : 0);
    }), this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    var e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  startMeasuringScrollPosition() {
    T(t, "scroll", this._updateScrollData, {
      capture: !0
    }), T(t, "scrollend", this._updateScrollData, {
      capture: !0
    }), T(t, "resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == t ? void 0 : t.document.documentElement;
    var e = B(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
    for (var i of e) {
      var r = null == t ? void 0 : t.document.querySelector(i);
      if (r) return r;
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollTop || 0;
    }
    return t && (t.scrollY || t.pageYOffset || t.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollLeft || 0;
    }
    return t && (t.scrollX || t.pageXOffset || t.document.documentElement.scrollLeft) || 0;
  }
}
var Yn = ie("[AutoCapture]");
function Kn(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
function Xn(e) {
  if (e.previousElementSibling) return e.previousElementSibling;
  var t = e;
  do {
    t = t.previousSibling;
  } while (t && !Oi(t));
  return t;
}
function Qn(e, t, i, r) {
  var s = e.tagName.toLowerCase(),
    n = {
      tag_name: s
    };
  ji.indexOf(s) > -1 && !i && ("a" === s.toLowerCase() || "button" === s.toLowerCase() ? n.$el_text = Kn(1024, tr(e)) : n.$el_text = Kn(1024, Hi(e)));
  var o = qi(e);
  o.length > 0 && (n.classes = o.filter(function (e) {
    return "" !== e;
  })), y(e.attributes, function (i) {
    var s;
    if ((!Gi(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == r || !r.includes(i.name)) && !t && er(i.value) && (s = i.name, !W(s) || "_ngcontent" !== s.substring(0, 10) && "_nghost" !== s.substring(0, 7))) {
      var o = i.value;
      "class" === i.name && (o = Di(o).join(" ")), n["attr__" + i.name] = Kn(1024, o);
    }
  });
  for (var a = 1, l = 1, c = e; c = Xn(c);) a++, c.tagName === e.tagName && l++;
  return n.nth_child = a, n.nth_of_type = l, n;
}
function Zn(e, i) {
  for (var r, s, {
      e: n,
      maskAllElementAttributes: o,
      maskAllText: a,
      elementAttributeIgnoreList: l,
      elementsChainAsString: c
    } = i, u = [e], d = e; d.parentNode && !Mi(d, "body");) Li(d.parentNode) ? (u.push(d.parentNode.host), d = d.parentNode.host) : (u.push(d.parentNode), d = d.parentNode);
  var h,
    p = [],
    _ = {},
    v = !1,
    g = !1;
  if (y(u, e => {
    var t = Vi(e);
    "a" === e.tagName.toLowerCase() && (v = e.getAttribute("href"), v = t && v && er(v) && v), $(qi(e), "ph-no-capture") && (g = !0), p.push(Qn(e, o, a, l));
    var i = function (e) {
      if (!Vi(e)) return {};
      var t = {};
      return y(e.attributes, function (e) {
        if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
          var i = e.name.replace("data-ph-capture-attribute-", ""),
            r = e.value;
          i && r && er(r) && (t[i] = r);
        }
      }), t;
    }(e);
    w(_, i);
  }), g) return {
    props: {},
    explicitNoCapture: g
  };
  if (a || ("a" === e.tagName.toLowerCase() || "button" === e.tagName.toLowerCase() ? p[0].$el_text = tr(e) : p[0].$el_text = Hi(e)), v) {
    var f, m;
    p[0].attr__href = v;
    var b = null === (f = wt(v)) || void 0 === f ? void 0 : f.host,
      S = null == t || null === (m = t.location) || void 0 === m ? void 0 : m.host;
    b && S && b !== S && (h = v);
  }
  return {
    props: w({
      $event_type: n.type,
      $ce_version: 1
    }, c ? {} : {
      $elements: p
    }, {
      $elements_chain: rr(p)
    }, null !== (r = p[0]) && void 0 !== r && r.$el_text ? {
      $el_text: null === (s = p[0]) || void 0 === s ? void 0 : s.$el_text
    } : {}, h && "click" === n.type ? {
      $external_click_url: h
    } : {}, _)
  };
}
class eo {
  constructor(e) {
    ae(this, "_initialized", !1), ae(this, "_isDisabledServerSide", null), ae(this, "rageclicks", new Bn()), ae(this, "_elementsChainAsString", !1), this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e,
      t,
      i = U(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (this.isBrowserSupported()) {
      if (t && a) {
        var e = e => {
          e = e || (null == t ? void 0 : t.event);
          try {
            this._captureEvent(e);
          } catch (e) {
            Yn.error("Failed to capture event", e);
          }
        };
        if (T(a, "submit", e, {
          capture: !0
        }), T(a, "change", e, {
          capture: !0
        }), T(a, "click", e, {
          capture: !0
        }), this.config.capture_copied_text) {
          var i = e => {
            e = e || (null == t ? void 0 : t.event), this._captureEvent(e, v);
          };
          T(a, "copy", i, {
            capture: !0
          }), T(a, "cut", i, {
            capture: !0
          });
        }
      }
    } else Yn.info("Disabling Automatic Event Collection because this browser is not supported");
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  onRemoteConfig(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [he]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t,
      i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      var r = null == a ? void 0 : a.querySelectorAll(t);
      null == r || r.forEach(r => {
        e === r && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e,
      t,
      i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[he],
      r = this._isDisabledServerSide;
    if (G(r) && !K(i) && !this.instance.config.advanced_disable_decide) return !1;
    var s = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !s;
  }
  _captureEvent(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (this.isEnabled) {
      var r,
        s = Ui(e);
      if (Ai(s) && (s = s.parentNode || null), "$autocapture" === i && "click" === e.type && e instanceof MouseEvent) this.instance.config.rageclick && null !== (r = this.rageclicks) && void 0 !== r && r.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, "$rageclick");
      var n = i === v;
      if (s && Wi(s, e, this.config, n, n ? ["copy", "cut"] : void 0)) {
        var {
          props: o,
          explicitNoCapture: a
        } = Zn(s, {
          e: e,
          maskAllElementAttributes: this.instance.config.mask_all_element_attributes,
          maskAllText: this.instance.config.mask_all_text,
          elementAttributeIgnoreList: this.config.element_attribute_ignorelist,
          elementsChainAsString: this._elementsChainAsString
        });
        if (a) return !1;
        var l = this.getElementSelectors(s);
        if (l && l.length > 0 && (o.$element_selectors = l), i === v) {
          var c,
            u = Bi(null == t || null === (c = t.getSelection()) || void 0 === c ? void 0 : c.toString()),
            d = e.type || "clipboard";
          if (!u) return !1;
          o.$selected_content = u, o.$copy_type = d;
        }
        return this.instance.capture(i, o), !0;
      }
    }
  }
  isBrowserSupported() {
    return H(null == a ? void 0 : a.querySelectorAll);
  }
}
var to = ie("[TracingHeaders]");
class io {
  constructor(e) {
    ae(this, "_restoreXHRPatch", void 0), ae(this, "_restoreFetchPatch", void 0), ae(this, "_startCapturing", () => {
      var e, t, i, r;
      z(this._restoreXHRPatch) && (null === (e = p.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
      z(this._restoreFetchPatch) && (null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.tracingHeadersPatchFns) || void 0 === r || r._patchFetch(this.instance.sessionManager));
    }), this.instance = e;
  }
  _loadScript(e) {
    var t, i, r;
    null !== (t = p.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "tracing-headers", t => {
      if (t) return to.error("failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
}
var ro;
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(ro || (ro = {}));
class so {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? ro.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === ro.DENIED || this.consent === ro.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    var {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    var e = this.storage.get(this.storageKey);
    return "1" === e ? ro.GRANTED : "0" === e ? ro.DENIED : ro.PENDING;
  }
  get storage() {
    if (!this._storage) {
      var e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? pt : dt;
      var t = "localStorage" === e ? dt : pt;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!R([null == o ? void 0 : o.doNotTrack, null == o ? void 0 : o.msDoNotTrack, p.doNotTrack], e => $([!0, 1, "1", "yes"], e));
  }
}
var no = ie("[ExceptionAutocapture]");
class oo {
  constructor(e) {
    var i;
    ae(this, "startCapturing", () => {
      var e, i, r, s;
      if (t && this.isEnabled && !this.hasHandlers) {
        var n = null === (e = p.__PosthogExtensions__) || void 0 === e || null === (i = e.errorWrappingFunctions) || void 0 === i ? void 0 : i.wrapOnError,
          o = null === (r = p.__PosthogExtensions__) || void 0 === r || null === (s = r.errorWrappingFunctions) || void 0 === s ? void 0 : s.wrapUnhandledRejection;
        if (n && o) try {
          this.unwrapOnError = n(this.captureException.bind(this)), this.unwrapUnhandledRejection = o(this.captureException.bind(this));
        } catch (e) {
          no.error("failed to start", e), this.stopCapturing();
        } else no.error("failed to load error wrapping functions - cannot start");
      }
    }), this.instance = e, this.remoteEnabled = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[_e]), this.startIfEnabled();
  }
  get isEnabled() {
    var e;
    return K(this.instance.config.capture_exceptions) ? this.instance.config.capture_exceptions : null !== (e = this.remoteEnabled) && void 0 !== e && e;
  }
  get hasHandlers() {
    return !z(this.unwrapOnError);
  }
  startIfEnabled() {
    this.isEnabled && !this.hasHandlers && (no.info("enabled, starting..."), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i;
    this.hasHandlers && e(), null === (t = p.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "exception-autocapture", t => {
      if (t) return no.error("failed to load script", t);
      e();
    });
  }
  stopCapturing() {
    var e, t;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), this.unwrapOnError = void 0, null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this), this.unwrapUnhandledRejection = void 0;
  }
  onRemoteConfig(e) {
    var t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.instance.persistence && this.instance.persistence.register({
      [_e]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    var t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = "".concat(t, "/project/").concat(this.instance.config.token, "/person/").concat(this.instance.get_distinct_id()), this.instance.exceptions.sendExceptionEvent(e);
  }
}
var ao = ie("[Web Vitals]"),
  lo = 9e5;
class co {
  constructor(e) {
    var t;
    ae(this, "_enabledServerSide", !1), ae(this, "_initialized", !1), ae(this, "buffer", {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    }), ae(this, "_flushToCapture", () => {
      clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => oe(oe({}, e), {}, {
        ["$web_vitals_".concat(t.name, "_event")]: oe({}, t),
        ["$web_vitals_".concat(t.name, "_value")]: t.value
      }), {})), this.buffer = {
        url: void 0,
        metrics: [],
        firstMetricTimestamp: void 0
      });
    }), ae(this, "_addToBuffer", e => {
      var t,
        i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
      if (z(i)) ao.error("Could not read session ID. Dropping metrics!");else {
        this.buffer = this.buffer || {
          url: void 0,
          metrics: [],
          firstMetricTimestamp: void 0
        };
        var r = this._currentURL();
        if (!z(r)) if (J(null == e ? void 0 : e.name) || J(null == e ? void 0 : e.value)) ao.error("Invalid metric received", e);else if (this._maxAllowedValue && e.value >= this._maxAllowedValue) ao.error("Ignoring metric with value >= " + this._maxAllowedValue, e);else this.buffer.url !== r && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), z(this.buffer.url) && (this.buffer.url = r), this.buffer.firstMetricTimestamp = z(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push(oe(oe({}, e), {}, {
          $current_url: r,
          $session_id: i.sessionId,
          $window_id: i.windowId,
          timestamp: Date.now()
        })), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
      }
    }), ae(this, "_startCapturing", () => {
      var e,
        t,
        i,
        r,
        s = p.__PosthogExtensions__;
      z(s) || z(s.postHogWebVitalsCallbacks) || ({
        onLCP: e,
        onCLS: t,
        onFCP: i,
        onINP: r
      } = s.postHogWebVitalsCallbacks), e && t && i && r ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && r(this._addToBuffer.bind(this)), this._initialized = !0) : ao.error("web vitals callbacks not loaded - not starting");
    }), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[ve]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e,
      t,
      i = U(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return z(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[fe]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (U(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    var e = U(this.instance.config.capture_performance) && Y(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : lo;
    return 0 < e && e <= 6e4 ? lo : e;
  }
  get isEnabled() {
    var e = U(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return K(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (ao.info("enabled, starting..."), this.loadScript(this._startCapturing));
  }
  onRemoteConfig(e) {
    var t = U(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = U(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [ve]: t
    }), this.instance.persistence.register({
      [fe]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = p.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "web-vitals", t => {
      t ? ao.error("failed to load script", t) : e();
    });
  }
  _currentURL() {
    var e = t ? t.location.href : void 0;
    return e || ao.error("Could not determine current URL"), e;
  }
}
var uo = {
  icontains: (e, i) => !!t && i.href.toLowerCase().indexOf(e.toLowerCase()) > -1,
  not_icontains: (e, i) => !!t && -1 === i.href.toLowerCase().indexOf(e.toLowerCase()),
  regex: (e, i) => !!t && A(i.href, e),
  not_regex: (e, i) => !!t && !A(i.href, e),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class ho {
  constructor(e) {
    var t = this;
    ae(this, "getWebExperimentsAndEvaluateDisplayLogic", function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      t.getWebExperiments(e => {
        ho.logInfo("retrieved web experiments from the server"), t._flagToExperiments = new Map(), e.forEach(e => {
          if (e.feature_flag_key) {
            var i;
            if (t._flagToExperiments) ho.logInfo("setting flag key ", e.feature_flag_key, " to web experiment ", e), null === (i = t._flagToExperiments) || void 0 === i || i.set(e.feature_flag_key, e);
            var r = t.instance.getFeatureFlag(e.feature_flag_key);
            W(r) && e.variants[r] && t.applyTransforms(e.name, r, e.variants[r].transforms);
          } else if (e.variants) for (var s in e.variants) {
            var n = e.variants[s];
            ho.matchesTestVariant(n) && t.applyTransforms(e.name, s, n.transforms);
          }
        });
      }, e);
    }), this.instance = e, this.instance.onFeatureFlags(e => {
      this.onFeatureFlags(e);
    });
  }
  onFeatureFlags(e) {
    if (this._is_bot()) ho.logInfo("Refusing to render web experiment since the viewer is a likely bot");else if (!this.instance.config.disable_web_experiments) {
      if (J(this._flagToExperiments)) return this._flagToExperiments = new Map(), this.loadIfEnabled(), void this.previewWebExperiment();
      ho.logInfo("applying feature flags", e), e.forEach(e => {
        var t;
        if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
          var i,
            r = this.instance.getFeatureFlag(e),
            s = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
          r && null != s && s.variants[r] && this.applyTransforms(s.name, r, s.variants[r].transforms);
        }
      });
    }
  }
  previewWebExperiment() {
    var e = ho.getWindowLocation();
    if (null != e && e.search) {
      var t = kt(null == e ? void 0 : e.search, "__experiment_id"),
        i = kt(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (ho.logInfo("previewing web experiments ".concat(t, " && ").concat(i)), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    var r = this.instance.get_property("$web_experiments");
    if (r && !t) return e(r);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/web_experiments/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        var i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    var r = i.filter(t => t.id === e);
    r && r.length > 0 && (ho.logInfo("Previewing web experiment [".concat(r[0].name, "] with variant [").concat(t, "]")), this.applyTransforms(r[0].name, t, r[0].variants[t].transforms));
  }
  static matchesTestVariant(e) {
    return !J(e.conditions) && ho.matchUrlConditions(e) && ho.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (J(e.conditions) || J(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    var i,
      r,
      s,
      n = ho.getWindowLocation();
    return !!n && (null === (i = e.conditions) || void 0 === i || !i.url || uo[null !== (r = null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType) && void 0 !== r ? r : "icontains"](e.conditions.url, n));
  }
  static getWindowLocation() {
    return null == t ? void 0 : t.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (J(e.conditions) || J(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    var i = xi.campaignParams();
    if (i.utm_source) {
      var r,
        s,
        n,
        o,
        a,
        l,
        c,
        u,
        d,
        h,
        p,
        _,
        v,
        g,
        f,
        m,
        b = null === (r = e.conditions) || void 0 === r || null === (s = r.utm) || void 0 === s || !s.utm_campaign || (null === (n = e.conditions) || void 0 === n || null === (o = n.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        y = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (c = e.conditions) || void 0 === c || null === (u = c.utm) || void 0 === u ? void 0 : u.utm_source) == i.utm_source,
        w = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (p = e.conditions) || void 0 === p || null === (_ = p.utm) || void 0 === _ ? void 0 : _.utm_medium) == i.utm_medium,
        S = null === (v = e.conditions) || void 0 === v || null === (g = v.utm) || void 0 === g || !g.utm_term || (null === (f = e.conditions) || void 0 === f || null === (m = f.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return b && w && S && y;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    te.info("[WebExperiments] ".concat(e), i);
  }
  applyTransforms(e, t, i) {
    this._is_bot() ? ho.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var r;
        ho.logInfo("applying transform of variant ".concat(t, " for experiment ").concat(e, " "), i);
        var s = null === (r = document) || void 0 === r ? void 0 : r.querySelectorAll(i.selector);
        null == s || s.forEach(e => {
          var t = e;
          i.html && (t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        });
      }
    }) : ho.logInfo("Control variants leave the page unmodified.");
  }
  _is_bot() {
    return o && this.instance ? qn(o, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
class po {
  constructor(e) {
    this.instance = e;
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent"
    });
  }
}
var _o = ["$set_once", "$set"],
  vo = ie("[SiteApps]");
class go {
  constructor(e) {
    this.instance = e, this.bufferedInvocations = [], this.apps = {};
  }
  get isEnabled() {
    return !!this.instance.config.opt_in_site_apps;
  }
  eventCollector(e, t) {
    if (t) {
      var i = this.globalsForEvent(t);
      this.bufferedInvocations.push(i), this.bufferedInvocations.length > 1e3 && (this.bufferedInvocations = this.bufferedInvocations.slice(10));
    }
  }
  get siteAppLoaders() {
    var e, t;
    return null === (e = p._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.siteApps;
  }
  init() {
    if (this.isEnabled) {
      var e = this.instance._addCaptureHook(this.eventCollector.bind(this));
      this.stopBuffering = () => {
        e(), this.bufferedInvocations = [], this.stopBuffering = void 0;
      };
    }
  }
  globalsForEvent(e) {
    var t, i, r, s, n, o, a;
    if (!e) throw new Error("Event payload is required");
    var l = {},
      c = this.instance.get_property("$groups") || [],
      u = this.instance.get_property("$stored_group_properties") || {};
    for (var [d, h] of Object.entries(u)) l[d] = {
      id: c[d],
      type: d,
      properties: h
    };
    var {
      $set_once: p,
      $set: _
    } = e;
    return {
      event: oe(oe({}, le(e, _o)), {}, {
        properties: oe(oe(oe({}, e.properties), _ ? {
          $set: oe(oe({}, null !== (t = null === (i = e.properties) || void 0 === i ? void 0 : i.$set) && void 0 !== t ? t : {}), _)
        } : {}), p ? {
          $set_once: oe(oe({}, null !== (r = null === (s = e.properties) || void 0 === s ? void 0 : s.$set_once) && void 0 !== r ? r : {}), p)
        } : {}),
        elements_chain: null !== (n = null === (o = e.properties) || void 0 === o ? void 0 : o.$elements_chain) && void 0 !== n ? n : "",
        distinct_id: null === (a = e.properties) || void 0 === a ? void 0 : a.distinct_id
      }),
      person: {
        properties: this.instance.get_property("$stored_person_properties")
      },
      groups: l
    };
  }
  setupSiteApp(e) {
    var t = {
      id: e.id,
      loaded: !1,
      errored: !1
    };
    this.apps[e.id] = t;
    var i = i => {
      var r;
      for (var s of (this.apps[e.id].errored = !i, this.apps[e.id].loaded = !0, vo.info("Site app with id ".concat(e.id, " ").concat(i ? "loaded" : "errored")), i && this.bufferedInvocations.length && (vo.info("Processing ".concat(this.bufferedInvocations.length, " events for site app with id ").concat(e.id)), this.bufferedInvocations.forEach(e => {
        var i;
        return null === (i = t.processEvent) || void 0 === i ? void 0 : i.call(t, e);
      })), Object.values(this.apps))) if (!s.loaded) return;
      null === (r = this.stopBuffering) || void 0 === r || r.call(this);
    };
    try {
      var {
        processEvent: r
      } = e.init({
        posthog: this.instance,
        callback: e => {
          i(e);
        }
      });
      r && (t.processEvent = r);
    } catch (t) {
      vo.error("Error while initializing PostHog app with config id ".concat(e.id), t), i(!1);
    }
  }
  onCapturedEvent(e) {
    if (0 !== Object.keys(this.apps).length) {
      var t = this.globalsForEvent(e);
      for (var i of Object.values(this.apps)) try {
        var r;
        null === (r = i.processEvent) || void 0 === r || r.call(i, t);
      } catch (t) {
        vo.error("Error while processing event ".concat(e.event, " for site app ").concat(i.id), t);
      }
    }
  }
  onRemoteConfig(e) {
    var t,
      i,
      r,
      s = this;
    if (null !== (t = this.siteAppLoaders) && void 0 !== t && t.length) {
      if (!this.isEnabled) return void vo.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
      for (var n of this.siteAppLoaders) this.setupSiteApp(n);
      this.instance.on("eventCaptured", e => this.onCapturedEvent(e));
    } else if (null === (i = this.stopBuffering) || void 0 === i || i.call(this), null !== (r = e.siteApps) && void 0 !== r && r.length) if (this.isEnabled) {
      var o = function (e, t) {
        var i, r;
        p["__$$ph_site_app_".concat(e)] = s.instance, null === (i = p.__PosthogExtensions__) || void 0 === i || null === (r = i.loadSiteApp) || void 0 === r || r.call(i, s.instance, t, t => {
          if (t) return vo.error("Error while initializing PostHog app with config id ".concat(e), t);
        });
      };
      for (var {
        id: a,
        url: l
      } of e.siteApps) o(a, l);
    } else vo.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
  }
}
function fo(e, t, i) {
  return Es({
    distinct_id: e,
    userPropertiesToSet: t,
    userPropertiesToSetOnce: i
  });
}
var mo = {},
  bo = () => {},
  yo = "posthog",
  wo = !ws && -1 === (null == h ? void 0 : h.indexOf("MSIE")) && -1 === (null == h ? void 0 : h.indexOf("Mozilla")),
  So = () => {
    var e;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: F(null == a ? void 0 : a.location),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: bo,
      save_campaign_params: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: l && W(null == l ? void 0 : l.search) && -1 !== l.search.indexOf("__posthog_debug=true") || !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      disable_external_dependency_loading: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == t || null === (e = t.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      mask_personal_data_properties: !1,
      custom_personal_data_properties: [],
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      on_request_error: e => {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        te.error(t);
      },
      get_device_id: e => e,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      before_send: void 0,
      request_queue_config: {
        flush_interval_ms: ms
      },
      _onCapture: bo
    };
  },
  ko = e => {
    var t = {};
    z(e.process_person) || (t.person_profiles = e.process_person), z(e.xhr_headers) || (t.request_headers = e.xhr_headers), z(e.cookie_name) || (t.persistence_name = e.cookie_name), z(e.disable_cookie) || (t.disable_persistence = e.disable_cookie), z(e.store_google) || (t.save_campaign_params = e.store_google), z(e.verbose) || (t.debug = e.verbose);
    var i = w({}, t, e);
    return B(e.property_blacklist) && (z(e.property_denylist) ? i.property_denylist = e.property_blacklist : B(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : te.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class Eo {
  constructor() {
    ae(this, "__forceAllowLocalhost", !1);
  }
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    te.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
}
class xo {
  get decideEndpointWasHit() {
    var e, t;
    return null !== (e = null === (t = this.featureFlags) || void 0 === t ? void 0 : t.hasLoadedFlags) && void 0 !== e && e;
  }
  constructor() {
    ae(this, "webPerformance", new Eo()), ae(this, "_personProcessingSetOncePropertiesSent", !1), ae(this, "version", _.LIB_VERSION), ae(this, "_internalEventEmitter", new xn()), this.config = So(), this.SentryIntegration = Ls, this.sentryIntegration = e => function (e, t) {
      var i = As(e, t);
      return {
        name: Ms,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this._cachedIdentify = null, this.featureFlags = new Qe(this), this.toolbar = new fs(this), this.scrollManager = new Jn(this), this.pageViewManager = new qs(this), this.surveys = new $n(this), this.experiments = new ho(this), this.exceptions = new po(this), this.rateLimiter = new Mn(this), this.requestRouter = new Os(this), this.consent = new so(this), this.people = {
      set: (e, t, i) => {
        var r = W(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(r), null == i || i({});
      },
      set_once: (e, t, i) => {
        var r = W(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, r), null == i || i({});
      }
    }, this.on("eventCaptured", e => te.info('send "'.concat(null == e ? void 0 : e.event, '"'), e));
  }
  init(e, t, i) {
    if (i && i !== yo) {
      var r,
        s = null !== (r = mo[i]) && void 0 !== r ? r : new xo();
      return s._init(e, t, i), mo[i] = s, mo[yo][i] = s, s;
    }
    return this._init(e, t, i);
  }
  _init(i) {
    var r,
      s,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      o = arguments.length > 2 ? arguments[2] : void 0;
    if (z(i) || V(i)) return te.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return te.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], n.person_profiles && (this._initialPersonProfilesConfig = n.person_profiles), this.set_config(w({}, So(), ko(n), {
      name: o,
      token: i
    })), this.config.on_xhr_error && te.error("on_xhr_error is deprecated. Use on_request_error instead"), this.compression = n.disable_compression ? void 0 : e.GZipJS, this.persistence = new Pi(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Pi(oe(oe({}, this.config), {}, {
      persistence: "sessionStorage"
    }));
    var a = oe({}, this.persistence.props),
      l = oe({}, this.sessionPersistence.props);
    if (this._requestQueue = new bs(e => this._send_retriable_request(e), this.config.request_queue_config), this._retryQueue = new Cs(this), this.__request_queue = [], this.config.__preview_experimental_cookieless_mode || (this.sessionManager = new Ts(this), this.sessionPropsManager = new Ln(this, this.sessionManager, this.persistence)), new io(this).startIfEnabledOrStop(), this.siteApps = new go(this), null === (r = this.siteApps) || void 0 === r || r.init(), this.config.__preview_experimental_cookieless_mode || (this.sessionRecording = new us(this), this.sessionRecording.startIfEnabledOrStop()), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new eo(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new Gn(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new co(this), this.exceptionObserver = new oo(this), this.exceptionObserver.startIfEnabled(), this.deadClicksAutocapture = new zn(this, jn), this.deadClicksAutocapture.startIfEnabled(), _.DEBUG = _.DEBUG || this.config.debug, _.DEBUG && te.info("Starting in debug mode", {
      this: this,
      config: n,
      thisC: oe({}, this.config),
      p: a,
      s: l
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (s = n.bootstrap) || void 0 === s ? void 0 : s.distinctID)) {
      var c,
        u,
        d = this.config.get_device_id(nt()),
        h = null !== (c = n.bootstrap) && void 0 !== c && c.isIdentifiedID ? d : n.bootstrap.distinctID;
      this.persistence.set_property(Le, null !== (u = n.bootstrap) && void 0 !== u && u.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: n.bootstrap.distinctID,
        $device_id: h
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var p,
        v,
        g = Object.keys((null === (p = n.bootstrap) || void 0 === p ? void 0 : p.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = n.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, r;
          return e[t] = (null === (i = n.bootstrap) || void 0 === i || null === (r = i.featureFlags) || void 0 === r ? void 0 : r[t]) || !1, e;
        }, {}),
        f = Object.keys((null === (v = n.bootstrap) || void 0 === v ? void 0 : v.featureFlagPayloads) || {}).filter(e => g[e]).reduce((e, t) => {
          var i, r, s, o;
          null !== (i = n.bootstrap) && void 0 !== i && null !== (r = i.featureFlagPayloads) && void 0 !== r && r[t] && (e[t] = null === (s = n.bootstrap) || void 0 === s || null === (o = s.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: g,
        featureFlagPayloads: f
      });
    }
    if (this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: ze,
      $device_id: null
    }, "");else if (!this.get_distinct_id()) {
      var m = this.config.get_device_id(nt());
      this.register_once({
        distinct_id: m,
        $device_id: m
      }, ""), this.persistence.set_property(Le, "anonymous");
    }
    return T(t, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this), {
      passive: !1
    }), this.toolbar.maybeLoadToolbar(), n.segment ? Ns(this, () => this._loaded()) : this._loaded(), H(this.config._onCapture) && this.config._onCapture !== bo && (te.warn("onCapture is deprecated. Please use `before_send` instead"), this.on("eventCaptured", e => this.config._onCapture(e.event, e))), this;
  }
  _onRemoteConfig(t) {
    var i, r, s, n, o, l, c, u;
    if (!a || !a.body) return te.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
      this._onRemoteConfig(t);
    }, 500);
    this.compression = void 0, t.supportedCompression && !this.config.disable_compression && (this.compression = $(t.supportedCompression, e.GZipJS) ? e.GZipJS : $(t.supportedCompression, e.Base64) ? e.Base64 : void 0), null !== (i = t.analytics) && void 0 !== i && i.endpoint && (this.analyticsDefaultEndpoint = t.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : "identified_only"
    }), null === (r = this.siteApps) || void 0 === r || r.onRemoteConfig(t), null === (s = this.sessionRecording) || void 0 === s || s.onRemoteConfig(t), null === (n = this.autocapture) || void 0 === n || n.onRemoteConfig(t), null === (o = this.heatmaps) || void 0 === o || o.onRemoteConfig(t), this.surveys.onRemoteConfig(t), null === (l = this.webVitalsAutocapture) || void 0 === l || l.onRemoteConfig(t), null === (c = this.exceptionObserver) || void 0 === c || c.onRemoteConfig(t), null === (u = this.deadClicksAutocapture) || void 0 === u || u.onRemoteConfig(t);
  }
  _loaded() {
    try {
      this.config.loaded(this);
    } catch (e) {
      te.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), new hs(this).load(), this.featureFlags.decide();
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || b(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (wo ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = ks(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = oe({}, this.config.request_headers), e.compression = "best-available" === e.compression ? this.compression : e.compression, e.fetchOptions = e.fetchOptions || this.config.fetch_options, (e => {
      var t,
        i,
        r,
        s = oe({}, e);
      s.timeout = s.timeout || 6e4, s.url = ks(s.url, {
        _: new Date().getTime().toString(),
        ver: _.LIB_VERSION,
        compression: s.compression
      });
      var n = null !== (t = s.transport) && void 0 !== t ? t : "fetch",
        o = null !== (i = null === (r = R(Is, e => e.transport === n)) || void 0 === r ? void 0 : r.method) && void 0 !== i ? i : Is[0].method;
      if (!o) throw new Error("No available transport method");
      o(s);
    })(oe(oe({}, e), {}, {
      callback: t => {
        var i, r, s;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (r = (s = this.config).on_request_error) || void 0 === r || r.call(s, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    }))));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    var t,
      i = [],
      r = [],
      s = [];
    b(e, e => {
      e && (t = e[0], B(t) ? s.push(e) : H(e) ? e.call(this) : B(e) && "alias" === t ? i.push(e) : B(e) && -1 !== t.indexOf("capture") && H(this[t]) ? s.push(e) : r.push(e));
    });
    var n = function (e, t) {
      b(e, function (e) {
        if (B(e[0])) {
          var i = t;
          y(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    n(i, this), n(r, this), n(s, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var r;
    if (this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue) {
      if (!this.consent.isOptedOut()) if (!z(e) && W(e)) {
        if (this.config.opt_out_useragent_filter || !this._is_bot()) {
          var s = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
          if (null == s || !s.isRateLimited) {
            this.sessionPersistence.update_search_keyword(), this.config.save_campaign_params && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.save_campaign_params || this.config.save_referrer) && this.persistence.set_initial_person_info();
            var n = new Date(),
              o = (null == i ? void 0 : i.timestamp) || n,
              a = nt(),
              l = {
                uuid: a,
                event: e,
                properties: this._calculate_event_properties(e, t || {}, o, a)
              };
            s && (l.properties.$lib_rate_limit_remaining_tokens = s.remainingTokens), (null == i ? void 0 : i.$set) && (l.$set = null == i ? void 0 : i.$set);
            var c = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            c && (l.$set_once = c), (l = P(l, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = o, z(null == i ? void 0 : i.timestamp) || (l.properties.$event_time_override_provided = !0, l.properties.$event_time_override_system_time = n);
            var u = oe(oe({}, l.properties.$set), l.$set);
            if (j(u) || this.setPersonPropertiesForFlags(u), !J(this.config.before_send)) {
              var d = this._runBeforeSend(l);
              if (!d) return;
              l = d;
            }
            this._internalEventEmitter.emit("eventCaptured", l);
            var h = {
              method: "POST",
              url: null !== (r = null == i ? void 0 : i._url) && void 0 !== r ? r : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: l,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(h) : this._requestQueue.enqueue(h), l;
          }
          te.critical("This capture call is ignored due to client rate limiting.");
        }
      } else te.error("No event name provided to posthog.capture");
    } else te.uninitializedWarning("posthog.capture");
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i, r) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    var s = this.persistence.remove_event_timer(e),
      n = oe({}, t);
    if (n.token = this.config.token, this.config.__preview_experimental_cookieless_mode && (n.$cookieless_mode = !0), "$snapshot" === e) {
      var o = oe(oe({}, this.persistence.properties()), this.sessionPersistence.properties());
      return n.distinct_id = o.distinct_id, (!W(n.distinct_id) && !Y(n.distinct_id) || V(n.distinct_id)) && te.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), n;
    }
    var l,
      c = xi.properties({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
    if (this.sessionManager) {
      var {
        sessionId: u,
        windowId: d
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      n.$session_id = u, n.$window_id = d;
    }
    if (this.sessionRecording && (n.$recording_status = this.sessionRecording.status), this.requestRouter.region === Fs.CUSTOM && (n.$lib_custom_api_host = this.config.api_host), l = "$pageview" === e ? this.pageViewManager.doPageView(i, r) : "$pageleave" === e ? this.pageViewManager.doPageLeave(i) : this.pageViewManager.doEvent(), n = w(n, l), "$pageview" === e && a && (n.title = a.title), !z(s)) {
      var p = i.getTime() - s;
      n.$duration = parseFloat((p / 1e3).toFixed(3));
    }
    h && this.config.opt_out_useragent_filter && (n.$browser_type = this._is_bot() ? "bot" : "browser"), (n = w({}, c, this.persistence.properties(), this.sessionPersistence.properties(), n)).$is_identified = this._isIdentified(), B(this.config.property_denylist) ? y(this.config.property_denylist, function (e) {
      delete n[e];
    }) : te.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    var _ = this.config.sanitize_properties;
    _ && (te.error("sanitize_properties is deprecated. Use before_send instead"), n = _(n, e));
    var v = this._hasPersonProcessing();
    return n.$process_person_profile = v, v && this._requirePersonProcessing("_calculate_event_properties"), n;
  }
  _calculate_set_once_properties(e) {
    var t;
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    if (this._personProcessingSetOncePropertiesSent) return e;
    var i = this.persistence.get_initial_props(),
      r = null === (t = this.sessionPropsManager) || void 0 === t ? void 0 : t.getSetOnceInitialSessionPropsProps(),
      s = w({}, i, r || {}, e || {}),
      n = this.config.sanitize_properties;
    return n && (te.error("sanitize_properties is deprecated. Use before_send instead"), s = n(s, "$set_once")), this._personProcessingSetOncePropertiesSent = !0, j(s) ? void 0 : s;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var r;
    null === (r = this.persistence) || void 0 === r || r.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    var t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch (e) {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return this.featureFlags.getEarlyAccessFeatures(e, t);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    this.surveys.canRenderSurvey(e);
  }
  getNextSurveyStep(e, t, i) {
    return this.surveys.getNextSurveyStep(e, t, i);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return te.uninitializedWarning("posthog.identify");
    if (Y(e) && (e = e.toString(), te.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
      if (["distinct_id", "distinctid"].includes(e.toLowerCase())) te.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
        var r = this.get_distinct_id();
        if (this.register({
          $user_id: e
        }), !this.get_property("$device_id")) {
          var s = r;
          this.register_once({
            $had_persisted_distinct_id: !0,
            $device_id: s
          }, "");
        }
        e !== r && e !== this.get_property(ue) && (this.unregister(ue), this.register({
          distinct_id: e
        }));
        var n = "anonymous" === (this.persistence.get_property(Le) || "anonymous");
        e !== r && n ? (this.persistence.set_property(Le, "identified"), this.setPersonPropertiesForFlags(oe(oe({}, i || {}), t || {}), !1), this.capture("$identify", {
          distinct_id: e,
          $anon_distinct_id: r
        }, {
          $set: t || {},
          $set_once: i || {}
        }), this.featureFlags.setAnonymousDistinctId(r), this._cachedIdentify = fo(e, t, i)) : (t || i) && (this._cachedIdentify !== fo(e, t, i) ? (this.setPersonProperties(t, i), this._cachedIdentify = fo(e, t, i)) : te.info("A duplicate posthog.identify call was made with the same properties. It has been ignored.")), e !== r && (this.reloadFeatureFlags(), this.unregister(Ae));
      }
    } else te.error("Unique user id has not been set in posthog.identify");
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(oe(oe({}, t || {}), e || {})), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (e && t) {
      if (this._requirePersonProcessing("posthog.group")) {
        var r = this.getGroups();
        r[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
          $groups: oe(oe({}, r), {}, {
            [e]: t
          })
        }), i && (this.capture("$groupidentify", {
          $group_type: e,
          $group_key: t,
          $group_set: i
        }), this.setGroupPropertiesForFlags({
          [e]: i
        })), r[e] === t || i || this.reloadFeatureFlags();
      }
    } else te.error("posthog.group requires a group type and group key");
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, r, s;
    if (te.info("reset"), !this.__loaded) return te.uninitializedWarning("posthog.reset");
    var n = this.get_property("$device_id");
    if (this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), this.surveys.reset(), null === (r = this.persistence) || void 0 === r || r.set_property(Le, "anonymous"), null === (s = this.sessionManager) || void 0 === s || s.resetSessionId(), this._cachedIdentify = null, this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: ze,
      $device_id: null
    }, "");else {
      var o = this.config.get_device_id(nt());
      this.register_once({
        distinct_id: o,
        $device_id: e ? o : n
      }, "");
    }
    this.register({
      $last_posthog_reset: new Date().toISOString()
    }, 1);
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    var {
        sessionId: t,
        sessionStartTimestamp: i
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0),
      r = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(t));
    if (null != e && e.withTimestamp && i) {
      var s,
        n = null !== (s = e.timestampLookBack) && void 0 !== s ? s : 10;
      if (!i) return r;
      var o = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - n, 0);
      r += "?t=".concat(o);
    }
    return r;
  }
  alias(e, t) {
    return e === this.get_property(ce) ? (te.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (z(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(ue, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : (te.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    var t,
      i,
      r,
      s,
      n = oe({}, this.config);
    U(e) && (w(this.config, ko(e)), null === (t = this.persistence) || void 0 === t || t.update_config(this.config, n), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Pi(oe(oe({}, this.config), {}, {
      persistence: "sessionStorage"
    })), pt.is_supported() && "true" === pt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (_.DEBUG = !0, te.info("set_config", {
      config: e,
      oldConfig: n,
      newConfig: oe({}, this.config)
    })), null === (i = this.sessionRecording) || void 0 === i || i.startIfEnabledOrStop(), null === (r = this.autocapture) || void 0 === r || r.startIfEnabled(), null === (s = this.heatmaps) || void 0 === s || s.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    var t = !0 === e,
      i = {
        sampling: t || !(null == e || !e.sampling),
        linked_flag: t || !(null == e || !e.linked_flag),
        url_trigger: t || !(null == e || !e.url_trigger),
        event_trigger: t || !(null == e || !e.event_trigger)
      };
    if (Object.values(i).some(Boolean)) {
      var r, s, n, o, a;
      if (null === (r = this.sessionManager) || void 0 === r || r.checkAndGetSessionAndWindowId(), i.sampling) null === (s = this.sessionRecording) || void 0 === s || s.overrideSampling();
      if (i.linked_flag) null === (n = this.sessionRecording) || void 0 === n || n.overrideLinkedFlag();
      if (i.url_trigger) null === (o = this.sessionRecording) || void 0 === o || o.overrideTrigger("url");
      if (i.event_trigger) null === (a = this.sessionRecording) || void 0 === a || a.overrideTrigger("event");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i,
      r = new Error("PostHog syntheticException"),
      s = H(null === (i = p.__PosthogExtensions__) || void 0 === i ? void 0 : i.parseErrorAsProperties) ? oe(oe({}, p.__PosthogExtensions__.parseErrorAsProperties(Q(e) ? {
        error: e,
        event: e.message
      } : {
        event: e
      }, {
        syntheticException: r
      })), t) : oe({
        $exception_level: "error",
        $exception_list: [{
          type: Q(e) ? e.name : "Error",
          value: Q(e) ? e.message : e,
          mechanism: {
            handled: !0,
            synthetic: !1
          }
        }]
      }, t);
    this.exceptions.sendExceptionEvent(s);
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e,
      t = null !== (e = this.config.name) && void 0 !== e ? e : yo;
    return t !== yo && (t = yo + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(Le)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(Le));
  }
  _hasPersonProcessing() {
    var e, t, i, r;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && j(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[ue]) && (null === (i = this.persistence) || void 0 === i || null === (r = i.props) || void 0 === r || !r[Ue]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? (te.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(Ue, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e,
      t,
      i,
      r,
      s = this.consent.isOptedOut(),
      n = this.config.opt_out_persistence_by_default,
      o = this.config.disable_persistence || s && !!n;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== o && (null === (i = this.persistence) || void 0 === i || i.set_disabled(o));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== o && (null === (r = this.sessionPersistence) || void 0 === r || r.set_disabled(o));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), z(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return o ? qn(o, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    a && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: a.title
    }, {
      send_instantly: !0
    }));
  }
  debug(e) {
    !1 === e ? (null == t || t.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == t || t.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
  _runBeforeSend(e) {
    if (J(this.config.before_send)) return e;
    var t = B(this.config.before_send) ? this.config.before_send : [this.config.before_send],
      i = e;
    for (var r of t) {
      if (i = r(i), J(i)) {
        var s = "Event '".concat(e.event, "' was rejected in beforeSend function");
        return Z(e.event) ? te.warn("".concat(s, ". This can cause unexpected behavior.")) : te.info(s), null;
      }
      i.properties && !j(i.properties) || te.warn("Event '".concat(e.event, "' has no properties after beforeSend function, this is likely an error."));
    }
    return i;
  }
  getPageViewId() {
    var e;
    return null === (e = this.pageViewManager._currentPageview) || void 0 === e ? void 0 : e.pageViewId;
  }
}
!function (e, t) {
  for (var i = 0; i < t.length; i++) e.prototype[t[i]] = x(e.prototype[t[i]]);
}(xo, ["identify"]);
var Io,
  Po = (Io = mo[yo] = new xo(), function () {
    function e() {
      e.done || (e.done = !0, wo = !1, y(mo, function (e) {
        e._dom_loaded();
      }));
    }
    null != a && a.addEventListener ? "complete" === a.readyState ? e() : T(a, "DOMContentLoaded", e, {
      capture: !1
    }) : t && te.error("Browser doesn't support `document.addEventListener` so PostHog couldn't be initialized");
  }(), Io);
export { v as COPY_AUTOCAPTURE_EVENT, e as Compression, xo as PostHog, En as SurveyQuestionBranchingType, kn as SurveyQuestionType, Sn as SurveyType, Po as default, g as knownUnsafeEditableEvent, Po as posthog, f as severityLevels };
